import React, {useEffect, useState} from "react";
import {
    getAllApplications,
    getPreSignedUrl
} from "../../requests/admin";
import {Section} from "./Section";
import {Table} from "../Table";
import {TooltipIcon} from "../Icon";

export const Applications = () => {
    const [applications, setApplications] = useState<any[]>();
    const [selectedApplication, setSelectedApplication] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadApplications();
    }, []);


    const loadApplications = () => {
        setIsLoading(true)
        getAllApplications()
            .then(res => setApplications(res)).finally(() => {
            setIsLoading(false)
        })
    }

    const generateLink = async (key: string) => {
        const url = await getPreSignedUrl(key)
        const link = document.createElement("a");
        link.href = url
        link.textContent = "Generated Link";
        link.target = "_blank";
        link.style.display = "none";
        link.click();
    };

    return <Section title={"Applications"} isLoading={isLoading} children={
        <div>
            {selectedApplication && <div>
                <div className="font-bold uppercase text-3xl flex items-center gap-5">

                    <button className="flex items-center p-2 hover:bg-gray-50 rounded-3xl"
                            onClick={() => setSelectedApplication(undefined)}>
                        <svg className="w-12 h-12 text-gray-800" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M13 5H1m0 0 4 4M1 5l4-4"/>
                        </svg>
                    </button>
                    Back to Applications
                </div>
                <div className="flex w-full justify-end p-4">

                </div>
                <div>
                    <div className="font-bold uppercase text-xl flex gap-10 mt-5">
                        <span>Application id:</span>
                        <span>{selectedApplication.id}</span>
                    </div>
                    <div className="font-bold uppercase text-xl flex gap-10 mt-5">
                        <span>First Name:</span>
                        <span>{selectedApplication.firstName}</span>
                    </div>
                    <div className="font-bold uppercase text-xl flex gap-10 mt-5">
                        <span>Last Name:</span>
                        <span>{selectedApplication.lastName}</span>
                    </div>
                    <div className="font-bold uppercase text-xl flex gap-10 mt-5">
                        <span>Email:</span>
                        <span>{selectedApplication.email}</span>
                    </div>
                    <div className="font-bold uppercase text-xl flex gap-10 mt-5">
                        <span>Phone number:</span>
                        <span>{selectedApplication.telephone}</span>
                    </div>
                    <div className="font-bold uppercase text-xl flex gap-10 mt-5">
                        <span>Documents</span>
                        <span>
                        {
                            selectedApplication && selectedApplication.documents.map((document: any) => {
                                return <div className="text-blue-600 hover:cursor-pointer"
                                            onClick={() => {
                                                generateLink(document)
                                            }}>
                                    {document.split("/")[document.split("/").length - 1]}
                                </div>
                            })
                        }
                        </span>
                    </div>

                </div>
            </div>}
            
            {!selectedApplication && applications && <Table columns={
                ["ID", "First name", "Last name", "Email", "Phone number", "ACTIONS"]
            } rows={applications.map(application => [application.id, application.firstName,
                application.lastName, application.email, application.telephone,
                <TooltipIcon icon={"open"} tooltipText={"Open application"}
                             onClick={() => setSelectedApplication(application.id)}/>])}/>}

        </div>
    }/>
}
