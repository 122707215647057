import {Section} from "../components/Section";
import React, {useEffect, useState} from "react";
import {Card} from "../components/Card";
import {getAllChallenges, getAllTournaments} from "../requests/public";
import {IChallenge} from "../types";

export const ChallengesPage = () => {
    const [challenges, setChallenges] = useState<IChallenge[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [advertisement, setAdvertisement] = useState<any[]>();
    useEffect(() => {
        setIsLoading(true)
        getAllChallenges().then((res) => {
            setChallenges(res);
        }).finally(() => {
            setIsLoading(false);
        });

        getAllTournaments().then(res => {
            console.log(res);
            setAdvertisement(res)
        })
    }, []);

    return <div>
        {isLoading && <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
            <div
                className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-64 w-64"></div>
        </div>}
        {!isLoading && <Section title="Code Challenges Hub" subTitle="Unlock Your Coding Potential"
                                ad={advertisement && {
                                    title: advertisement[0].title,
                                    description: advertisement[0].description
                                }}>
            {challenges.map((challenge) => <Card title={challenge.title}
                                                 description={challenge.description} path={challenge.id}
                                                 buttonTitle={"To game"}
                                                 secondButtonTitle={"P2P"}
                                                 secondPath={`p2p/${challenge.id}`}
                                                 key={challenge.id}/>)}

        </Section>}
    </div>
}