import {Loading} from "../Loading";
import React from "react";

interface ISectionProps {
    title: string;
    isLoading:boolean;
    children?: JSX.Element;
}

export const Section = (props:ISectionProps) => <div className="p-14">
    <Loading isLoading={props.isLoading}/>
    <div className="font-bold uppercase text-3xl pb-10">{props.title}</div>
    <div className="relative overflow-x-auto">
        {props.children}
    </div>

</div>