import axios from "axios";
import {IJob} from "./jobs";


const ADMIN_BASE_URL = "https://3v82x7r9s1.execute-api.eu-central-1.amazonaws.com/dev/admin";


/* *************************************************************
* Users
* **************************************************************
* */

export interface IUser {
    Attributes: { Name: string, Value: string }[],
    Username: string;
    UserStatus: string;
    UserCreateDate: string;
    Enabled: boolean;
}

export const getAllUsers = async () => {
    let res = await axios.get<IUser[]>(`${ADMIN_BASE_URL}/users`);
    return res.data;
}


/* *************************************************************
* Challenges
* **************************************************************
* **/

export const getAllChallenges = async () => {
    let res = await axios.get(`${ADMIN_BASE_URL}/challenges`);
    return await res.data;
}

export const getChallengesSchema = async () => {
    let res = await axios.get(`${ADMIN_BASE_URL}/challenges/schema`);
    return await res.data;
}


export const editChallenge = async (challengeId: string, challenge: any) => {
    let res = await axios.put(`${ADMIN_BASE_URL}/challenges/${challengeId}`, challenge);
    return await res.data;
}

export const resetChallenges = async () => {
    let res = await axios.get(`${ADMIN_BASE_URL}/challenges/load`);
    console.log(res)
    return await res.data;
}

/* *************************************************************
* Jobs
* **************************************************************
* **/

export const getJobsSchema = async () => {
    let res = await axios.get(`${ADMIN_BASE_URL}/jobs/schema`);
    return await res.data;
}

export const getAllJobs = async (filter?: string[]) => {
    const queryfilter = filter && filter.map(f => "filter=" + f);
    let url = `${ADMIN_BASE_URL}/jobs`
    if (queryfilter) url += "?" + queryfilter.join("&")
    let res = await axios.get(url);
    return await res.data;
}

export const createNewJob = async (job: IJob) => {
    let res = await axios.post(ADMIN_BASE_URL + "/jobs", job);
    return await res.data;
}

export const deleteJob = async (jobId: string) => {
    let res = await axios.delete(`${ADMIN_BASE_URL}/jobs/${jobId}`);
    return await res.data;
}

export const editJob = async (jobId: string, job: IJob) => {
    let res = await axios.put(`${ADMIN_BASE_URL}/jobs/${jobId}`, job);
    return await res.data;
}

export const resetJobs = async () => {
    let res = await axios.get(`${ADMIN_BASE_URL}/jobs/load`);
    return await res.data;
}


export const getAllApplications = () => {
    return axios.get(ADMIN_BASE_URL + "/applications").then(res => res.data);
}

export const getPreSignedUrl = (key: string) => {
    return axios.get(ADMIN_BASE_URL + `/applications/pre-signed-url?file=${key}`).then(res => res.data);
}


/* *************************************************************
* Tournaments
* **************************************************************
* **/

export const createNewTournament = async (tournament: any) => {
    let res = await axios.post(ADMIN_BASE_URL + `/tournaments`, tournament);
    return await res.data;
}

export const getAllTournaments = async () => {
    let res = await axios.get(ADMIN_BASE_URL + `/tournaments`);
    return await res.data;
}

export const deleteTournament = async (id: string) => {
    let res = await axios.delete(ADMIN_BASE_URL + `/tournaments/${id}`);
    return await res.data;
}

export const getCreateNewTournamentSchema = async () => {
    let res = await axios.get(ADMIN_BASE_URL + `/tournaments/schema`);
    return await res.data;
}