import p5Types from "p5";
import { ACTIONS } from "./Actions";
import { Shot } from "./Shot";

export class Ship {
  public x: number;
  public y: number;
  public shipWidth: number;
  public shipHeight: number;
  private cannonWidth: number;
  private shotInterval: number;
  private lastShotFiredTimestamp: number;
  private color: string;

  constructor(
    private p5: p5Types,
    private canvasWidth: number,
    private canvasHeight: number
  ) {
    this.x = this.canvasWidth / 2;
    this.y = this.canvasHeight - 16;
    this.shipWidth = 80;
    this.shipHeight = 20;
    this.cannonWidth = 5;
    this.shotInterval = 20;
    this.lastShotFiredTimestamp = -this.shotInterval;
    this.color = "#86bc25";
  }

  getShipInformation() {
    return {
      x: this.x,
      y: this.y,
      width: this.shipWidth,
    };
  }

  // draws the player
  draw() {
    this.p5.fill(this.color);
    this.p5.rectMode(this.p5.CENTER);
    this.p5.noStroke();
    this.drawShip(this.x, this.y);
  }

  // stores and draws geometry for player's ship
  drawShip(x: number, y: number) {
    this.p5.rect(x, y, this.shipWidth, this.shipHeight, 2);
    this.p5.rect(
      x,
      y - this.shipHeight / 2 - this.cannonWidth / 2,
      this.cannonWidth,
      this.cannonWidth
    );
    this.p5.rect(x, y - this.shipHeight / 2 - this.cannonWidth - 1, 2, 2);
  }

  takeAction(action: ACTIONS, shots: Shot[]) {
    if (action === ACTIONS.LEFT || action === ACTIONS.RIGHT) {
      this.move(action);
    } else if (action === ACTIONS.FIRE) {
      this.fire(shots);
    }
  }

  move(action: ACTIONS) {
    if (ACTIONS.LEFT === action && this.x > this.shipWidth / 2) {
      this.x -= 10;
    } else if (
      ACTIONS.RIGHT === action &&
      this.x < this.p5.width - this.shipWidth / 2
    ) {
      this.x += 10;
    }
  }

  fire(shots: Shot[]) {
    if (this.p5.frameCount - this.lastShotFiredTimestamp > this.shotInterval) {
      shots.push(new Shot(this.p5, this.x, this.y - this.shipHeight));
      this.lastShotFiredTimestamp = this.p5.frameCount;
      return true;
    }
    return false;
  }

  die() {
    this.color = "red";
  }
  isAlive() {
    if (this.color === "red") return false;
    return true;
  }
}
