import {Auth} from "aws-amplify";
import axios from "axios";

const PRIVATE_BASE_URL = "https://3v82x7r9s1.execute-api.eu-central-1.amazonaws.com/dev/private";

export const getChallengeBots = async (challengeType: string) => {
    const accessToken = await Auth.currentSession().then((res: any) => {
        return res.accessToken.getJwtToken();
    });
    return axios.get(`${PRIVATE_BASE_URL}/challenges/${challengeType}/bots`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`, // Replace with your access token
        }
    });
}


export const createNewBot = async (challengeType: string, botName: string) => {
    const accessToken = await Auth.currentSession().then((res: any) => {
        return res.accessToken.getJwtToken();
    });
    return axios.post(`${PRIVATE_BASE_URL}/challenges/${challengeType}/bots`, {
        botName
    }, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`, // Replace with your access token
        }
    });
}


export const deleteBot = async (challengeType: string, botId: string) => {
    const accessToken = await Auth.currentSession().then((res: any) => {
        return res.accessToken.getJwtToken();
    });
    return axios.delete(`${PRIVATE_BASE_URL}/challenges/${challengeType}/bots/${botId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
    });
}


export const updateBot = async (challengeType: string, botId: string, code: string, botName: string) => {
    const accessToken = await Auth.currentSession().then((res: any) => {
        return res.accessToken.getJwtToken();
    });
    return axios.put(`${PRIVATE_BASE_URL}/challenges/${challengeType}/bots/${botId}`, {
        code,
        botName
    }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
    });
}


export const getTournaments = async (challengeId: string) => {
    const accessToken = await Auth.currentSession().then((res: any) => {
        return res.accessToken.getJwtToken();
    });
    return axios.get(`${PRIVATE_BASE_URL}/tournaments?challengeId=${challengeId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
    });
}


export const registerBot = async (tournamentId: string, bot: any) => {
    const accessToken = await Auth.currentSession().then((res: any) => {
        return res.accessToken.getJwtToken();
    });
    return axios.post(`${PRIVATE_BASE_URL}/tournaments/${tournamentId}/register`, bot,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        }
    )
        ;
}


export const unregisterBot = async (tournamentId: string) => {
    const accessToken = await Auth.currentSession().then((res: any) => {
        return res.accessToken.getJwtToken();
    });
    return axios.delete(`${PRIVATE_BASE_URL}/tournaments/${tournamentId}/unregister`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        }
    );
}

export const getCodeApplicationsForm = async () => {
    const accessToken = await Auth.currentSession().then((res: any) => {
        return res.accessToken.getJwtToken();
    });
    return axios.get(`${PRIVATE_BASE_URL}/applications`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        }
    );
}


export const inviteFreind = async (to: string, email: string) => {
    const accessToken = await Auth.currentSession().then((res: any) => {
        return res.accessToken.getJwtToken();
    });
    return axios.post(`${PRIVATE_BASE_URL}/email`, {
            to, email
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        }
    )
        ;
}