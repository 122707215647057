import {useEffect} from "react";
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";

export const SignOutPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        Auth.signOut().then(res=> navigate("/")).catch(e=>navigate("/"))
    }, []);

    return <div></div>
}