import {useEffect, useState} from "react";
import {LoginForm} from "../components/Auth/Login";
import {SignupForm} from "../components/Auth/Signup";
import {ConfirmationForm} from "../components/Auth/Confirmation";
import {useLocation} from "react-router-dom";
import {Footer} from "../components/Footer";

enum FormType {
    LOGIN = "login",
    SIGNUP = "signup",
    CONFIRM = "confirm"
}

export const AuthPage = () => {
    const [type, setType] = useState<FormType>();
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split("/");
        setType(path[path.length - 1] as FormType)
    }, [location.pathname]);


    return <div>
        {type === FormType.LOGIN && <LoginForm/>}
        {type === FormType.SIGNUP && <SignupForm/>}
        {type === FormType.CONFIRM && <ConfirmationForm/>}
        <Footer/>
    </div>
}