import React, {FormEvent, useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {Link, useNavigate} from "react-router-dom";

export const SignupForm = () => {
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
    const [error, setError] = useState<string>();
    const navigate = useNavigate();
    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (email && password && firstName && lastName)
            Auth.signUp({
                password: password,
                username: email,
                attributes: {"given_name": firstName, "family_name": lastName}
            }).then((res) => {
                navigate(`/auth/confirm?email=${email}&message=signup`)
            }).catch(e => setError(e.message));
    }

    useEffect(() => {
        if (password && passwordConfirmation && passwordConfirmation !== password) setError("Passwörter stimmen nicht überein");
        else setError("");
    }, [passwordConfirmation]);

    useEffect(() => {
       if(error)
           setError("")
    }, [email, password, firstName, lastName]);

    return <section>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto relative h-[80vh] lg:py-0">
            <div
                className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                        Konto erstellen
                    </h1>
                    <form className="space-y-4 md:space-y-6" action="#" onSubmit={onSubmit}>
                        <div>
                            <label htmlFor="firstName"
                                   className="block mb-2 text-sm font-medium text-gray-900">Vorname</label>
                            <input type="text" name="firstName" id="firstName"
                                   onChange={(e) => setFirstName(e.target.value)}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#86bc24] focus:border-[#86bc24] block w-full p-2.5"
                                   required/>
                        </div>
                        <div>
                            <label htmlFor="lastName"
                                   className="block mb-2 text-sm font-medium text-gray-900">Nachname</label>
                            <input type="text" name="lastName" id="lastName"
                                   onChange={(e) => setLastName(e.target.value)}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#86bc24] focus:border-[#86bc24] block w-full p-2.5"
                                   required/>
                        </div>
                        <div>
                            <label htmlFor="email"
                                   className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                            <input type="email" name="email" id="email"
                                   onChange={(e) => setEmail(e.target.value)}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#86bc24] focus:border-[#86bc24] block w-full p-2.5"
                                   required/>
                        </div>
                        <div>
                            <label htmlFor="password"
                                   className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input type="password" name="password" id="password"
                                   onChange={(e) => setPassword(e.target.value)}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#86bc24] focus:border-[#86bc24] block w-full p-2.5"
                                   required/>
                        </div>
                        <div>
                            <label htmlFor="confirm-password"
                                   className="block mb-2 text-sm font-medium text-gray-900">Passwort
                                bestätigen</label>
                            <input type="password" name="confirm-password" id="confirm-password"
                                   onChange={(e) => setPasswordConfirmation(e.target.value)}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg  focus:ring-[#86bc24] focus:border-[#86bc24] block w-full p-2.5"
                                   required/>
                        </div>
                        <div className="flex items-start">
                            <div className="flex items-center h-5">
                                <input id="terms" aria-describedby="terms" type="checkbox"
                                       className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-[#86bc24] focus:border-[#86bc24]"
                                       required/>

                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="terms" className="font-light text-gray-500">Ich akzeptiere die <Link
                                    className="font-medium text-primary-600 hover:underline"
                                    to="/privacy"> Geschäftsbedingungen</Link></label>
                            </div>
                        </div>
                        <p className="mt-2 text-sm text-red-600">{error}</p>
                        <button type="submit"
                                disabled={!!error}
                                className="disabled:bg-gray-400 w-full text-white bg-[#86bc24] hover:bg-primary-700 focus:ring-[#86bc24] focus:border-[#86bc24] font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            Registrieren
                        </button>
                        <p className="text-sm font-light text-gray-500">
                            Sie haben ein Konto? <Link to="/auth/login" className="font-medium text-primary-600 hover:underline">Jetzt anmelden</Link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </section>
}