import styles from "../styles/privacy.module.scss";
import React from "react";

export const PrivacyPage = () => {
    return (
        <div className={styles.container}>
            <section className={styles["header-section"]}>
                <h1 className={styles["header-section-title"]}>
                    DATENSCHUTZ­ERKLÄRUNG
                </h1>
            </section>
            <section>
                <h2 className={styles["section-title"]}>
                    1. DATENSCHUTZ AUF EINEN BLICK
                </h2>
                <h2 className={styles["section-subtitle"]}>ALLGEMEINE HINWEISE</h2>
                <p className={styles["section-text"]}>
                    Die folgenden Hinweise geben einen einfachen Überblick darüber, was
                    mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
                    besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                    persönlich identifiziert werden können. Ausführliche Informationen
                    zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
                    aufgeführten Datenschutzerklärung.
                </p>
                <h2 className={styles["section-subtitle"]}>
                    DATENERFASSUNG AUF DIESER WEBSITE
                </h2>
                <h3 className={styles["section-subsubtitle"]}>
                    WER IST VERANTWORTLICH FÜR DIE DATENERFASSUNG AUF DIESER WEBSITE?
                </h3>
                <p className={styles["section-text"]}>
                    Die Datenverarbeitung auf dieser Website erfolgt durch den
                    Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
                    „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
                    entnehmen.
                </p>

                <h3 className={styles["section-subsubtitle"]}>
                    WIE ERFASSEN WIR IHRE DATEN?
                </h3>
                <p className={styles["section-text"]}>
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                    mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
                    ein Kontaktformular eingeben.
                    <br/>
                    Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
                    Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                    allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
                    Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                    automatisch, sobald Sie diese Website betreten.
                </p>

                <h3 className={styles["section-subsubtitle"]}>
                    WOFÜR NUTZEN WIR IHRE DATEN?
                </h3>
                <p className={styles["section-text"]}>
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
                    der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
                    Nutzerverhaltens verwendet werden.
                </p>

                <h3 className={styles["section-subsubtitle"]}>
                    WELCHE RECHTE HABEN SIE BEZÜGLICH IHRER DATEN?
                </h3>
                <p className={styles["section-text"]}>
                    Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
                    Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
                    erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
                    Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
                    Datenverarbeitung erteilt haben, können Sie diese Einwilligung
                    jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
                    unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
                    Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                    <br/>
                    Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
                    sich jederzeit an uns wenden.
                </p>

                <h2 className={styles["section-subtitle"]}>
                    ANALYSE-TOOLS UND TOOLS VON DRITT­ANBIETERN
                </h2>
                <p className={styles["section-text"]}>
                    Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
                    ausgewertet werden. Das geschieht vor allem mit sogenannten
                    Analyseprogrammen.
                    <br/>
                    Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
                    der folgenden Datenschutzerklärung.
                </p>
            </section>

            <section>
                <h2 className={styles["section-title"]}>
                    2. HOSTING UND CONTENT DELIVERY NETWORKS (CDN)
                </h2>
                <h2 className={styles["section-subtitle"]}>HETZNER</h2>
                <p className={styles["section-text"]}>
                    Wir hosten unsere Website bei Hetzner. Anbieter ist die Hetzner
                    Online GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend
                    Hetzner).
                    <br/>
                    Details entnehmen Sie der Datenschutzerklärung von Hetzner:
                    https://www.hetzner.com/de/rechtliches/datenschutz.
                    <br/>
                    Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1
                    lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
                    möglichst zuverlässigen Darstellung unserer Website. Sofern eine
                    entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                    ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
                    Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
                    oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
                    Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
                    ist jederzeit widerrufbar.
                </p>
                <h3 className={styles["section-subsubtitle"]}>
                    AUFTRAGSVERARBEITUNG
                </h3>
                <p className={styles["section-text"]}>
                    Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
                    genannten Anbieter geschlossen. Hierbei handelt es sich um einen
                    datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet,
                    dass dieser die personenbezogenen Daten unserer Websitebesucher nur
                    nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
                </p>
                <h2 className={styles["section-subtitle"]}>CLOUDFLARE</h2>
                <p className={styles["section-text"]}>
                    Wir nutzen den Service „Cloudflare“. Anbieter ist die Cloudflare
                    Inc., 101 Townsend St., San Francisco, CA 94107, USA (im Folgenden
                    „Cloudflare”).
                    <br/>
                    Cloudflare bietet ein weltweit verteiltes Content Delivery Network
                    mit DNS an. Dabei wird technisch der Informationstransfer zwischen
                    Ihrem Browser und unserer Website über das Netzwerk von Cloudflare
                    geleitet. Das versetzt Cloudflare in die Lage, den Datenverkehr
                    zwischen Ihrem Browser und unserer Website zu analysieren und als
                    Filter zwischen unseren Servern und potenziell bösartigem
                    Datenverkehr aus dem Internet zu dienen. Hierbei kann Cloudflare
                    auch Cookies oder sonstige Technologien zur Wiedererkennung von
                    Internetnutzern einsetzen, die jedoch allein zum hier beschriebenen
                    Zweck verwendet werden.
                    <br/>
                    Der Einsatz von Cloudflare beruht auf unserem berechtigten Interesse
                    an einer möglichst fehlerfreien und sicheren Bereitstellung unseres
                    Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
                    <br/>
                    Die Datenübertragung in die USA wird auf die
                    Standardvertragsklauseln der EU-Kommission gestützt. Details finden
                    Sie hier: https://www.cloudflare.com/privacypolicy/.
                    <br/>
                    Weitere Informationen zum Thema Sicherheit und Datenschutz bei
                    Cloudflare finden Sie hier:
                    https://www.cloudflare.com/privacypolicy/.
                </p>
            </section>

            <section>
                <h2 className={styles["section-title"]}>
                    3. ALLGEMEINE HINWEISE UND PFLICHT­INFORMATIONEN
                </h2>
                <h2 className={styles["section-subtitle"]}>DATENSCHUTZ</h2>
                <p className={styles["section-text"]}>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
                    Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                    vertraulich und entsprechend den gesetzlichen
                    Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    <br/>
                    Wenn Sie diese Website benutzen, werden verschiedene
                    personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
                    mit denen Sie persönlich identifiziert werden können. Die
                    vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
                    und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
                    Zweck das geschieht.
                    <br/>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
                    bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
                    Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                    nicht möglich.
                </p>
                <h2 className={styles["section-subtitle"]}>
                    HINWEIS ZUR VERANTWORTLICHEN STELLE
                </h2>
                <p className={styles["section-text"]}>
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                    Website ist:
                    <br/>
                    bridgefield GmbH <br/>
                    Jerichower Straße 28-30 <br/>
                    39114 Magdeburg
                    <br/>
                    <br/>
                    Telefon: +49 (0) 391 79 29 30 0 <br/>
                    E-Mail: info@bridgefield.de
                    <br/>
                    Verantwortliche Stelle ist die natürliche oder juristische Person,
                    die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                    Verarbeitung von personenbezogenen Daten (z. B. Namen,
                    E-Mail-Adressen o. Ä.) entscheidet.
                </p>
                <h2 className={styles["section-subtitle"]}>SPEICHERDAUER</h2>
                <p className={styles["section-text"]}>
                    Soweit innerhalb dieser Datenschutzerklärung keine speziellere
                    Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
                    bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
                    ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
                    zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
                    wir keine anderen rechtlich zulässigen Gründe für die Speicherung
                    Ihrer personenbezogenen Daten haben (z. B. steuer- oder
                    handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
                    erfolgt die Löschung nach Fortfall dieser Gründe.
                </p>
                <h2 className={styles["section-subtitle"]}>
                    ALLGEMEINE HINWEISE ZU DEN RECHTSGRUNDLAGEN DER DATENVERARBEITUNG
                    AUF DIESER WEBSITE
                </h2>
                <p className={styles["section-text"]}>
                    Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
                    wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1
                    lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
                    Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im
                    Falle einer ausdrücklichen Einwilligung in die Übertragung
                    personenbezogener Daten in Drittstaaten erfolgt die
                    Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a
                    DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff
                    auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting)
                    eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf
                    Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
                    widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
                    Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten
                    wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
                    Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung
                    einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von
                    Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
                    Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
                    DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
                    Rechtsgrundlagen wird in den folgenden Absätzen dieser
                    Datenschutzerklärung informiert.
                </p>
                <h2 className={styles["section-subtitle"]}>
                    DATENSCHUTZ­BEAUFTRAGTER
                </h2>
                <p className={styles["section-text"]}>
                    Wir haben für unser Unternehmen einen Datenschutzbeauftragten
                    bestellt.
                    <br/>
                    <br/>
                    Christian Metzeler
                    <br/>
                    Metzeler-DS – Agiler Datenschutz & Beratung
                    <br/>
                    Halberstädter Straße 90
                    <br/>
                    39112 Magdeburg
                    <br/>
                    <br/>
                    Telefon: +49 391 59776307
                    <br/>
                    E-Mail: cm@metzeler-ds.de
                    <br/>
                </p>
                <h2 className={styles["section-subtitle"]}>
                    HINWEIS ZUR DATENWEITERGABE IN DIE USA UND SONSTIGE DRITTSTAATEN
                </h2>
                <p className={styles["section-text"]}>
                    Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den
                    USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
                    Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in
                    diese Drittstaaten übertragen und dort verarbeitet werden. Wir
                    weisen darauf hin, dass in diesen Ländern kein mit der EU
                    vergleichbares Datenschutzniveau garantiert werden kann.
                    Beispielsweise sind US-Unternehmen dazu verpflichtet,
                    personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
                    dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
                    kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
                    Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
                    Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
                    Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
                </p>

                <h2 className={styles["section-subtitle"]}>
                    WIDERRUF IHRER EINWILLIGUNG ZUR DATENVERARBEITUNG
                </h2>
                <p className={styles["section-text"]}>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
                    Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
                    jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
                    erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </p>
                <h2 className={styles["section-subtitle"]}>
                    WIDERSPRUCHSRECHT GEGEN DIE DATENERHEBUNG IN BESONDEREN FÄLLEN SOWIE
                    GEGEN DIREKTWERBUNG (ART. 21 DSGVO)
                </h2>
                <p className={styles["section-text"]}>
                    WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
                    ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
                    DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
                    VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
                    DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
                    DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                    ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
                    EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
                    MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
                    GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
                    UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
                    GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
                    (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                    <br/>
                    WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
                    BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
                    VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
                    DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
                    SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                    WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                    NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
                    ART. 21 ABS. 2 DSGVO).
                </p>
                <h2 className={styles["section-subtitle"]}>
                    BESCHWERDE­RECHT BEI DER ZUSTÄNDIGEN AUFSICHTS­BEHÖRDE
                </h2>
                <p className={styles["section-text"]}>
                    Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
                    Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
                    Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                    oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
                    besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
                    gerichtlicher Rechtsbehelfe.
                </p>
                <h2 className={styles["section-subtitle"]}>
                    RECHT AUF DATEN­ÜBERTRAG­BARKEIT
                </h2>
                <p className={styles["section-text"]}>
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
                    oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
                    oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                    aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
                    an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
                    soweit es technisch machbar ist.
                </p>
                <h2 className={styles["section-subtitle"]}>
                    SSL- BZW. TLS-VERSCHLÜSSELUNG
                </h2>
                <p className={styles["section-text"]}>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                    Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
                    oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
                    bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
                    Sie daran, dass die Adresszeile des Browsers von „http://“ auf
                    „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                    <br/>
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                    Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                    werden.
                </p>
                <h2 className={styles["section-subtitle"]}>
                    AUSKUNFT, LÖSCHUNG UND BERICHTIGUNG
                </h2>
                <p className={styles["section-text"]}>
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                    jederzeit das Recht auf unentgeltliche Auskunft über Ihre
                    gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
                    und den Zweck der Datenverarbeitung und ggf. ein Recht auf
                    Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
                    Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
                    uns wenden.
                </p>

                <h2 className={styles["section-subtitle"]}>
                    RECHT AUF EINSCHRÄNKUNG DER VERARBEITUNG
                </h2>
                <p className={styles["section-text"]}>
                    Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen. Hierzu können Sie sich
                    jederzeit an uns wenden. Das Recht auf Einschränkung der
                    Verarbeitung besteht in folgenden Fällen:
                    <div className={styles["list"]}>
                        <p className={styles["list-item"]}>
                            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                            personenbezogenen Daten bestreiten, benötigen wir in der Regel
                            Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                            das Recht, die Einschränkung der Verarbeitung Ihrer
                            personenbezogenen Daten zu verlangen.
                        </p>
                        <p className={styles["list-item"]}>
                            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                            geschah/geschieht, können Sie statt der Löschung die
                            Einschränkung der Datenverarbeitung verlangen.
                        </p>
                        <p className={styles["list-item"]}>
                            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                            sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                            Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                            Löschung die Einschränkung der Verarbeitung Ihrer
                            personenbezogenen Daten zu verlangen.
                        </p>
                        <p className={styles["list-item"]}>
                            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                            haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                            vorgenommen werden. Solange noch nicht feststeht, wessen
                            Interessen überwiegen, haben Sie das Recht, die Einschränkung
                            der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                        </p>
                    </div>
                    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                    eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
                    abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
                    Rechte einer anderen natürlichen oder juristischen Person oder aus
                    Gründen eines wichtigen öffentlichen Interesses der Europäischen
                    Union oder eines Mitgliedstaats verarbeitet werden.
                </p>
            </section>
            <section>
                <h2 className={styles["section-title"]}>
                    4. DATENERFASSUNG AUF DIESER WEBSITE
                </h2>
                <h2 className={styles["section-subtitle"]}>COOKIES</h2>
                <p className={styles["section-text"]}>
                    Unsere Internetseiten verwenden KEINE so genannte „Cookies“.
                </p>
                <h2 className={styles["section-subtitle"]}>SERVER-LOG-DATEIEN</h2>
                <p className={styles["section-text"]}>
                    Der Provider der Seiten erhebt und speichert automatisch
                    Informationen in so genannten Server-Log-Dateien, die Ihr Browser
                    automatisch an uns übermittelt. Dies sind:
                    <div className={styles.list}>
                        <p className={styles["list-item"]}>
                            Browsertyp und Browserversion
                        </p>
                        <p className={styles["list-item"]}>verwendetes Betriebssystem</p>
                        <p className={styles["list-item"]}>Referrer URL</p>
                        <p className={styles["list-item"]}>
                            Hostname des zugreifenden Rechners
                        </p>
                        <p className={styles["list-item"]}>Uhrzeit der Serveranfrage</p>
                        <p className={styles["list-item"]}>IP-Adresse</p>
                    </div>
                    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
                    nicht vorgenommen.
                    <br/>
                    Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
                    lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
                    der technisch fehlerfreien Darstellung und der Optimierung seiner
                    Website – hierzu müssen die Server-Log-Files erfasst werden.
                </p>
                <h2 className={styles["section-subtitle"]}>KONTAKTFORMULAR</h2>
                <p className={styles["section-text"]}>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
                    Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
                    angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
                    Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
                    nicht ohne Ihre Einwilligung weiter.
                    <br/>
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
                    1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
                    zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                    erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
                    auf unserem berechtigten Interesse an der effektiven Bearbeitung der
                    an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
                    Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
                    abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
                    <br/>
                    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
                    uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
                    Speicherung widerrufen oder der Zweck für die Datenspeicherung
                    entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
                    Zwingende gesetzliche Bestimmungen – insbesondere
                    Aufbewahrungsfristen – bleiben unberührt.
                </p>

                <h2 className={styles["section-subtitle"]}>
                    ANFRAGE PER E-MAIL, TELEFON ODER TELEFAX
                </h2>
                <p className={styles["section-text"]}>
                    Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                    Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
                    Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
                    uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
                    Ihre Einwilligung weiter.
                    <br/>
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
                    1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
                    zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                    erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
                    auf unserem berechtigten Interesse an der effektiven Bearbeitung der
                    an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
                    Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
                    abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
                    <br/>
                    Die von Ihnen an uns per Kontaktanfragen übersandten Daten
                    verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
                    Einwilligung zur Speicherung widerrufen oder der Zweck für die
                    Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
                    Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
                    gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                </p>
            </section>

            <section>
                <h2 className={styles["section-title"]}>5. SOZIALE MEDIEN</h2>
                <h2 className={styles["section-subtitle"]}>XING</h2>
                <p className={styles["section-text"]}>
                    Diese Website nutzt Elemente des Netzwerks XING. Anbieter ist die
                    New Work SE, Dammtorstraße 30, 20354 Hamburg, Deutschland.
                    <br/>
                    Bei jedem Abruf einer unserer Seiten, die Elemente von XING enthält,
                    wird eine Verbindung zu Servern von XING hergestellt. Eine
                    Speicherung von personenbezogenen Daten erfolgt dabei nach unserer
                    Kenntnis nicht. Insbesondere werden keine IP-Adressen gespeichert
                    oder das Nutzungsverhalten ausgewertet.
                    <br/>
                    Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
                    Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a
                    DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
                    Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung
                    des Dienstes auf Grundlage unseres berechtigten Interesses an einer
                    möglichst umfassenden Sichtbarkeit in den Sozialen Medien.
                    <br/>
                    Weitere Information zum Datenschutz und dem XING Share-Button finden
                    Sie in der Datenschutzerklärung von XING unter:
                    https://www.xing.com/app/share?op=data_protection.
                </p>
            </section>

            <section>
                <h2 className={styles["section-title"]}>
                    6. AUDIO- UND VIDEOKONFERENZEN
                </h2>
                <h2 className={styles["section-subsubtitle"]}>DATENVERARBEITUNG</h2>
                <p className={styles["section-text"]}>
                    Für die Kommunikation mit unseren Kunden setzen wir unter anderen
                    Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten Tools
                    sind unten aufgelistet. Wenn Sie mit uns per Video- oder
                    Audiokonferenz via Internet kommunizieren, werden Ihre
                    personenbezogenen Daten von uns und dem Anbieter des jeweiligen
                    Konferenz-Tools erfasst und verarbeitet.
                    <br/>
                    Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung
                    der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre
                    Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer der
                    Konferenz, Beginn und Ende (Zeit) der Teilnahme an der Konferenz,
                    Anzahl der Teilnehmer und sonstige „Kontextinformationen“ im
                    Zusammenhang mit dem Kommunikationsvorgang (Metadaten).
                    <br/>
                    Des Weiteren verarbeitet der Anbieter des Tools alle technischen
                    Daten, die zur Abwicklung der Online-Kommunikation erforderlich
                    sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen,
                    Geräte-IDs, Gerätetyp, Betriebssystemtyp und -version,
                    Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie die Art
                    der Verbindung.
                    <br/>
                    Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in
                    sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf
                    den Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten
                    zählen insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten,
                    Voicemails hochgeladene Fotos und Videos, Dateien, Whiteboards und
                    andere Informationen, die während der Nutzung des Dienstes geteilt
                    werden.
                    <br/>
                    Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die
                    Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere
                    Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik
                    des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung
                    durch die Konferenztools entnehmen Sie den Datenschutzerklärungen
                    der jeweils eingesetzten Tools, die wir unter diesem Text aufgeführt
                    haben.
                </p>

                <h2 className={styles["section-subsubtitle"]}>
                    ZWECK UND RECHTSGRUNDLAGEN
                </h2>
                <p className={styles["section-text"]}>
                    Die Konferenz-Tools werden genutzt, um mit angehenden oder
                    bestehenden Vertragspartnern zu kommunizieren oder bestimmte
                    Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 lit. b
                    DSGVO). Des Weiteren dient der Einsatz der Tools der allgemeinen
                    Vereinfachung und Beschleunigung der Kommunikation mit uns bzw.
                    unserem Unternehmen (berechtigtes Interesse im Sinne von Art. 6 Abs.
                    1 lit. f DSGVO). Soweit eine Einwilligung abgefragt wurde, erfolgt
                    der Einsatz der betreffenden Tools auf Grundlage dieser
                    Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die
                    Zukunft widerrufbar.
                </p>

                <h2 className={styles["section-subsubtitle"]}>SPEICHERDAUER</h2>
                <p className={styles["section-text"]}>
                    Die unmittelbar von uns über die Video- und Konferenz-Tools
                    erfassten Daten werden von unseren Systemen gelöscht, sobald Sie uns
                    zur Löschung auffordern, Ihre Einwilligung zur Speicherung
                    widerrufen oder der Zweck für die Datenspeicherung entfällt.
                    Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie
                    löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben
                    unberührt.
                    <br/>
                    Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
                    Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir
                    keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte
                    direkt bei den Betreibern der Konferenz-Tools.
                </p>

                <h2 className={styles["section-subsubtitle"]}>
                    EINGESETZTE KONFERENZ-TOOLS
                </h2>
                <p className={styles["section-text"]}>
                    Wir setzen folgende Konferenz-Tools ein:
                </p>
                <h2 className={styles["section-subtitle"]}>MICROSOFT TEAMS</h2>
                <p className={styles["section-text"]}>
                    Wir nutzen Microsoft Teams. Anbieter ist die Microsoft Corporation,
                    One Microsoft Way, Redmond, WA 98052-6399, USA. Details zur
                    Datenverarbeitung entnehmen Sie der Datenschutzerklärung von
                    Microsoft Teams:
                    https://privacy.microsoft.com/de-de/privacystatement.
                </p>
            </section>

            <section>
                <h2 className={styles["section-title"]}>7. EIGENE DIENSTE</h2>
                <h2 className={styles["section-subtitle"]}>
                    UMGANG MIT BEWERBERDATEN
                </h2>
                <p className={styles["section-text"]}>
                    Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B.
                    per E-Mail, postalisch oder via Online-Bewerberformular). Im
                    Folgenden informieren wir Sie über Umfang, Zweck und Verwendung
                    Ihrer im Rahmen des Bewerbungsprozesses erhobenen personenbezogenen
                    Daten. Wir versichern, dass die Erhebung, Verarbeitung und Nutzung
                    Ihrer Daten in Übereinstimmung mit geltendem Datenschutzrecht und
                    allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten
                    streng vertraulich behandelt werden.
                </p>
                <h2 className={styles["section-subsubtitle"]}>
                    UMFANG UND ZWECK DER DATENERHEBUNG
                </h2>
                <p className={styles["section-text"]}>
                    Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre
                    damit verbundenen personenbezogenen Daten (z. B. Kontakt- und
                    Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
                    Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
                    Begründung eines Beschäftigungsverhältnisses erforderlich ist.
                    Rechtsgrundlage hierfür ist § 26 BDSG nach deutschem Recht
                    (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b
                    DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine
                    Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die
                    Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen Daten
                    werden innerhalb unseres Unternehmens ausschließlich an Personen
                    weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt
                    sind.
                    <br/>
                    Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
                    eingereichten Daten auf Grundlage von § 26 BDSG und Art. 6 Abs. 1
                    lit. b DSGVO zum Zwecke der Durchführung des
                    Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen
                    gespeichert.
                </p>

                <h2 className={styles["section-subsubtitle"]}>
                    AUFBEWAHRUNGSDAUER DER DATEN
                </h2>
                <p className={styles["section-text"]}>
                    Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
                    Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten
                    wir uns das Recht vor, die von Ihnen übermittelten Daten auf
                    Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f
                    DSGVO) bis zu 6 Monate ab der Beendigung des Bewerbungsverfahrens
                    (Ablehnung oder Zurückziehung der Bewerbung) bei uns aufzubewahren.
                    Anschließend werden die Daten gelöscht und die physischen
                    Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient insbesondere
                    Nachweiszwecken im Falle eines Rechtsstreits. Sofern ersichtlich
                    ist, dass die Daten nach Ablauf der 6-Monatsfrist erforderlich sein
                    werden (z. B. aufgrund eines drohenden oder anhängigen
                    Rechtsstreits), findet eine Löschung erst statt, wenn der Zweck für
                    die weitergehende Aufbewahrung entfällt.
                    <br/>
                    Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
                    entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt
                    haben oder wenn gesetzliche Aufbewahrungspflichten der Löschung
                    entgegenstehen.
                </p>
                <h2 className={styles["section-subsubtitle"]}>
                    AUFNAHME IN DEN BEWERBER-POOL
                </h2>
                <p className={styles["section-text"]}>
                    Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die
                    Möglichkeit, Sie in unseren Bewerber-Pool aufzunehmen. Im Falle der
                    Aufnahme werden alle Dokumente und Angaben aus der Bewerbung in den
                    Bewerber-Pool übernommen, um Sie im Falle von passenden Vakanzen zu
                    kontaktieren.
                    <br/>
                    Die Aufnahme in den Bewerber-Pool geschieht ausschließlich auf
                    Grundlage Ihrer ausdrücklichen Einwilligung (Art. 6 Abs. 1 lit. a
                    DSGVO). Die Abgabe der Einwilligung ist freiwillig und steht in
                    keinem Bezug zum laufenden Bewerbungsverfahren. Der Betroffene kann
                    seine Einwilligung jederzeit widerrufen. In diesem Falle werden die
                    Daten aus dem Bewerber-Pool unwiderruflich gelöscht, sofern keine
                    gesetzlichen Aufbewahrungsgründe vorliegen.
                    <br/>
                    Die Daten aus dem Bewerber-Pool werden spätestens zwei Jahre nach
                    Erteilung der Einwilligung unwiderruflich gelöscht.
                </p>
            </section>
        </div>
    );
}
