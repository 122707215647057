import p5Types from "p5";
import React, {lazy, Suspense} from "react";
import {PongGameController} from "../../games/pong/Main";
import {GameController} from "../../games/GameController";
import {SpaceInvadersGameController} from "../../games/space-invaders/Main";
const Sketch = lazy(() => import('react-p5'));

export enum GAME_TYPE {
    SPACE_INVANDERS = "SPACE_INVADERS",
    PONG = "PONG",
}

interface IGameCanvas {
    strategies: { botName: string; code: string }[];
    gameType: string;
    width: number;
    height: number;
    manualControl: { [key: number]: boolean };
    config: any;
    start: boolean;
}

export const GameCanvas = (props: IGameCanvas) => {
    let gameController: any;

    const preload = (p5: p5Types) => {
        if (props.gameType === GAME_TYPE.PONG) {
            gameController = new PongGameController(
                p5,
                props.strategies,
                props.height,
                props.width,
                props.config,
                props.manualControl,
            );
        }

        if (props.gameType === GAME_TYPE.SPACE_INVANDERS) {
            gameController = new SpaceInvadersGameController(
                p5,
                props.strategies,
                props.height,
                props.width,
                props.config,
                props.manualControl,

            );
        }
    };

    const setup = (live:boolean) => (p5: p5Types, canvasParentRef: Element) => {
        if (canvasParentRef) {
            p5.createCanvas(props.width, props.height).parent(canvasParentRef);
            p5.frameRate(32);
            if(!live) {
                p5.noLoop();
            }
        }
    };

    const draw = (p5: p5Types) => {
        if (gameController) gameController.run(props.start);
    };

    return <div>
        <Suspense fallback={<div>Hi, This page is Loading...</div>}>
            {props.start && <Sketch preload={preload} setup={setup(true)} draw={draw}/>}
            {!props.start && <Sketch preload={preload} setup={setup(false)} draw={draw}/>}
        </Suspense>

    </div>
};
