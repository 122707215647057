import p5Types from "p5";
import {ACTION} from "./Actions";

export class Paddle {
    public position: p5Types.Vector;

    constructor(
        private p5: p5Types,
        public canvasWidth: number,
        public canvasHeight: number,
        public canvasHeightMargin: number,
        public isLeft: boolean,
        public width: number,
        public height: number,
        public velocity: number,
        public score: number
    ) {
        this.position = this.p5.createVector(
            0,
            this.canvasHeight / 2 - this.height / 2
        );
        if (this.isLeft) {
            this.position.x = 20;
        } else {
            this.position.x = this.canvasWidth - this.width - 20;
        }
    }

    draw() {
        if (this.p5 !== undefined && (this.p5 as any)._setupDone !== false) {
            this.p5.rect(this.position.x, this.position.y, this.width, this.height);
        }
    }

    update(action: ACTION) {
        if (
            action === ACTION.UP &&
            this.position.y >= this.canvasHeightMargin + 12
        ) {
            this.position.y -= this.velocity;
        } else if (
            action === ACTION.DOWN &&
            this.position.y <=
            this.canvasHeight - this.canvasHeightMargin - 12 - this.height
        ) {
            this.position.y += this.velocity;
        }
    }
}
