import React from "react";
import {Footer} from "./Footer";

interface ISectionProps {
    title: string;
    subTitle: string;
    children: JSX.Element | JSX.Element[];
    ad?: {
        description: string;
        title: string;
    }
}

export const Section = (props: ISectionProps) => {
    return (
        <div>
            <div className="min-h-[73vh]">
                <div className="w-full text-center">
                    <h1 className="mb-4 mt-20 text-3xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl uppercase">
                        {props.title}</h1>
                    <p className="mb-4 mt-10 text-lg font-extrabold leading-none tracking-tight text-white md:text3xl lg:text-3xl">
                        {props.subTitle}</p>
                    <hr
                        className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-[#86bc24] to-transparent"/>
                    {props.ad &&
                        <p className="mb-4 mt-10 text-md font-extrabold leading-none tracking-tight text-white md:text-xl lg:text-xl flex justify-center items-center flex-col bg-[#86bc24]">
                        <span
                            className="mb-4 text-lg font-extrabold leading-none tracking-tight text-white md:text-3xl lg:text-3xl bg-[#86bc24] w-[40rem]">
                            {props.ad?.title}</span>
                            <span
                                className="mb-4  text-md font-extrabold leading-none tracking-tight text-white md:text-xl lg:text-xl bg-[#86bc24]">
                            {props.ad?.description}</span>
                        </p>}

                </div>
                <div className="flex justify-center align-middle gap-3 flex-wrap overflow-y-auto pb-10">
                    {props.children}
                </div>


            </div>
            <Footer/>
        </div>


    )
}