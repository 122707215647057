import React, {FormEvent, useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {Link, useNavigate} from "react-router-dom";
import {ErrorType} from "../../config/config";


export const LoginForm = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [error, setError] = useState<string>();

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        Auth.signIn(email!, password!).then((res) => {
            navigate(-1);
        }).catch(e => {
            if (e.name == ErrorType.UserNotConfirmedException) {
                navigate(`/auth/confirm?email=${email}&message=${ErrorType.UserNotConfirmedException}`)
            } else if (e.name == ErrorType.NotAuthorizedException) {
                setError(e.message)
            } else {
                setError(e.message)
            }
        })
    };


    useEffect(() => {
        if (error) {
            setError("")
        }
    }, [email, password]);

    return <section>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0 relative h-[80vh]">
            <div
                className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                        Anmeldung
                    </h1>
                    <form className="space-y-4 md:space-y-6" action="#" onSubmit={onSubmit}>
                        <div>
                            <label htmlFor="email"
                                   className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                            <input type="email" name="email" id="email"
                                   className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#86bc24] focus:border-[#86bc24] block w-full p-2.5"
                                   onChange={(e) => setEmail(e.target.value)}
                                   placeholder="name@company.com" required/>
                        </div>
                        <div>
                            <label htmlFor="password"  className="block mb-2 text-sm font-medium text-gray-900">Passwort</label>
                            <input type="password" name="password" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#86bc24] focus:border-[#86bc24] block w-full p-2.5"
                                   onChange={(e) => setPassword(e.target.value)}
                                   required/>
                        </div>

                        <p className="mt-2 text-sm text-red-600">{error}</p>
                        <button type="submit"
                                className="disabled:bg-gray-400 w-full text-white bg-[#86bc24] focus:ring-[#86bc24] focus:border-[#86bc24] font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            Einloggen
                        </button>
                        <p className="text-sm font-light text-gray-500">
                             Neu hier? <p
                            className="inline cursor-pointer font-medium text-primary-600 hover:underline"><Link
                            to="/auth/signup"
                            className="font-medium text-primary-600 hover:underline">Jetzt registrieren</Link></p>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </section>
}