import React, {Fragment} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Auth} from "aws-amplify";
import {Avatar} from 'flowbite-react';


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}


interface INavbarProps {
    isAuthenticated: boolean;
    isAdmin: boolean;
    userName?: string
}

export const Navbar = (props: INavbarProps) => {
    const navigate = useNavigate();
    const location = useLocation();


    const onSignOut = () => {
        Auth.signOut();
    }

    const ButtonLinkL = (props: { name: string, to: string }) => {
        return <button type="button"
                       className="text-white text-lg hover:bg-[#86bc24]/90 font-medium rounded-lg px-5 py-2.5 text-center mr-2 mb-2 uppercase"
                       onClick={() => navigate(props.to)}>{props.name}</button>
    }

    const ButtonLinkS = (props: { name: string, to: string }) => {
        return <Link to={props.to}> <Disclosure.Button
            className={classNames(
                location.pathname.split("/")[1] === props.to ? 'bg-gray-900 text-white uppercase' : 'text-gray-300 hover:bg-gray-700 hover:text-white uppercase',
                'block rounded-md px-3 py-2 text-base font-medium'
            )}
        >
            {props.name}
        </Disclosure.Button></Link>
    }

    const MenuItem = (props: { name: string, to: string }) => {
        return <Menu.Item>
            {({active}) => (
                <Link
                    to="#"
                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 ')}
                >
                    {props.name}
                </Link>
            )}
        </Menu.Item>
    }

    return (
        <Disclosure as="nav" className="bg-transparent">
            {({open}) => (
                <>
                    <div className="mx-auto  px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                <Disclosure.Button
                                    className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5"/>
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <Link to="/">
                                        <img
                                            alt="logo"
                                            className="h-10 w-auto"
                                            src="/logo_bf.png"
                                        />
                                    </Link>
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        <ButtonLinkL name="Jobs" to="jobs"/>
                                        <ButtonLinkL name="Challenges" to="challenges"/>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {!props.isAuthenticated &&
                                    <ButtonLinkL name="Sign in" to="auth/login"/>
                                }
                                {props.isAdmin && props.isAuthenticated &&
                                    <div className="hidden md:block">
                                        <ButtonLinkL name="Administration" to="admin"/>
                                    </div>
                                }
                                {props.isAuthenticated && props.userName && <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button
                                            className="relative flex scale-150 rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="absolute -inset-1.5"/>
                                            <span className="sr-only">Open user menu</span>
                                            <Avatar placeholderInitials={props.userName} rounded className="rounded-lg"/>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <button onClick={() => navigate("/auth/signout")}
                                                    className="w-full text-left"><MenuItem name="Sign out"
                                                                                           to="/auth/signout"/></button>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>}
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {props.isAdmin && props.isAuthenticated && <ButtonLinkS name="Administration" to="admin"/>}
                            <ButtonLinkS name="Challenges" to="challenges"/>
                            <ButtonLinkS name="Jobs" to="jobs"/>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}