import React, {useEffect, useState} from 'react';
import {Sidebar} from "../components/Challenge/Sidebar";
import {CodeEditor} from "../components/Challenge/Editor";
import {TooltipIcon} from "../components/Icon";
import {GameCanvas} from "../components/Challenge/Canvas";
import {getAllJobs, getApplicationForm, getChallenge, submitApplication} from "../requests/public";
import {useParams} from "react-router-dom";
import {getChallengeBots, getTournaments, inviteFreind, updateBot} from "../requests/private";
import typescript from "typescript";
import {Modal, Select} from 'flowbite-react';
import {IJob} from '../requests/jobs';
import {toast} from "react-toastify";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import {Auth} from "aws-amplify";

export interface IChallenge {
    challengeType: string,
    config: { [key: string]: string },
    description: string,
    goal: string;
    id: string,
    numberOfPlayers: number,
    starterCode: string,
    title: string
}

export interface ITournament {
    id: string;
    title: string;
    withVipCodes: boolean;
    registeredBot: IBot;
}

export interface IBot {
    id: string;
    botName: string;
    code: string;
}

export const ChallengePage = () => {
    const challengeId = useParams().id;
    const [challenge, setChallenge] = useState<IChallenge>();
    const [bots, setBots] = useState<IBot[]>([]);
    const [tournaments, setTournaments] = useState<ITournament[]>([]);
    const [ownBot, setOwnBot] = useState<IBot>();
    const [opponentBot, setOpponentBot] = useState<IBot>();
    const [gameStarted, setGameStarted] = useState(false);
    const [isBotOnLeftSide, setIsBotOnLeftSide] = useState(true);
    const [jobs, setJobs] = useState<IJob[]>([]);
    const [freindEmail, setFreindEmail] = useState<string>("");
    const [openApplicationForm, setOpenApplicationForm] = useState(false)

    useEffect(() => {
        loadChallenge();

    }, [challengeId]);


    useEffect(() => {
        loadBots();
        loadTournaments();
        loadJobs();
    }, [challenge]);

    useEffect(() => {
        if (bots) {
            setOwnBot(bots[bots.length - 1]);
        }
    }, [bots]);

    const loadTournaments = () => {
        if (challenge) {
            getTournaments(challenge.id)
                .then((res) => {
                    setTournaments(res.data);
                    console.log(res.data)
                }).catch(e => console.log("Error: ", e))

        }
    }


    const loadJobs = () => {
        if (challenge) {
            getAllJobs()
                .then((res) => {
                    setJobs(res.data)
                }).catch(e => console.log("Error: ", e))

        }
    }

    const checkUserError = (e: string) => {
        return (e === "No current user")
    }

    const loadChallenge = () => {
        if (challengeId) {
            getChallenge(challengeId)
                .then(res => {
                    setChallenge(res.data)
                }).catch(e => console.log("Error: ", checkUserError(e)))
        }
    }

    const loadBots = () => {
        if (challenge) {
            getChallengeBots(challenge.id)
                .then(res => setBots(res.data))
                .catch(e => {
                    if (checkUserError(e)) {
                        setBots([{botName: "Default", code: challenge.starterCode, id: "Default"}])
                    }
                })
        }
    }


    const onSave = (botId: string, code: string, botName: string) => {
        if (challenge) {
            updateBot(challenge.challengeType, botId, code, botName)
                .then(res => loadBots())
        }
    }

    const onOwnBotSelection = (bot: IBot) => {
        setOwnBot(bot);
    }

    const switchSide = (e: boolean) => {
        setIsBotOnLeftSide(e);
    }


    const onReload = () => {
        loadBots();
        loadTournaments();
    }

    const onSubmit = () => {
        // submitApplication({...s.formData, jobTitle: job.title}).then(res => {
        //     toast.success(`Your application for the position ${job.title} is successfully submitted.`);
        // }).catch(e => {
        //     toast.error(`An error occurred while submitting your application. Please try again later.`);
        // }).finally(() => {
        //     setIsLoading(false);
        // })
    }

    const onReset = (botId: string, botName: string) => {
        if (challenge)
            updateBot(challenge.challengeType, botId, challenge.starterCode, botName)
                .then(res => loadBots())
    }

    const onAction = (bot: IBot) => {
        setGameStarted(!gameStarted)
        setOwnBot(bot)
    }

    const toggleApplication = ()=>{
        setOpenApplicationForm(!openApplicationForm)
    }

    const buildStrategies = () => {
        const strategies: any[] = [];
        if (challenge && ownBot) {
            strategies[isBotOnLeftSide ? 0 : 1] = {
                botName: ownBot.botName,
                code: `${typescript.transpile(ownBot.code)};
                    console.log = function(message) {
                      postMessage({ type: 'log', message: message });
                    };
                    onmessage = function(e) {
                      postMessage(step(JSON.parse(e.data)));
                    }`,
            };
        }

        if (challenge && opponentBot) {
            strategies[isBotOnLeftSide ? 1 : 0] = {
                botName: opponentBot.botName,
                code: `${typescript.transpile(opponentBot.code)};
                    console.log = function(message) {
                      postMessage({ type: 'log', message: message });
                    };
                    onmessage = function(e) {
                      postMessage(step(JSON.parse(e.data)));
                    }`,
            };
        }

        return strategies;
    };


    return <div className="flex flex-col">
        <div className="flex bg-white h-16 min-w-screen border-b">
            <div className="flex justify-between items-center w-full">
                <div className="w-80 flex justify-center items-center gap-5">
                    <h1 className="text-xl font-extrabold leading-none tracking-tight text-black md:text-5xl lg:text-2xl uppercase">{challenge?.title}</h1>
                    <TooltipIcon icon="info" tooltipText={challenge?.goal || ""}></TooltipIcon>
                </div>
                <form className="flex items-center justify-center mr-12" onSubmit={async (e)=>{
                    e.preventDefault();
                    if(freindEmail) {
                        const email = await Auth.currentUserInfo().then(res=> res.email);
                        inviteFreind(freindEmail, email).then(()=>{
                            toast.success("Invitation successfully sent.")
                        }).catch(()=>{
                            toast.error("An error occurred")
                        }).finally(()=>{
                            setFreindEmail("")
                        })
                    }
                }}>
                    <label htmlFor="simple-search" className="sr-only">Search</label>
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">

                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                <path
                                    d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-2V5a1 1 0 0 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 1 0 0-2Z"/>
                            </svg>
                        </div>
                        <input type="text"
                               value={freindEmail}
                               onChange={(e)=>{setFreindEmail(e.target.value)}}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-200 focus:border-green-50 block w-full pl-10 p-2.5"
                               required placeholder="Invite a friend..."/>
                    </div>
                </form>
            </div>
        </div>


        <div className="flex min-h-screen min-w-screen bg-white">
            {challenge && bots && <div className="flex-1 h-screen border">
                <Sidebar bots={bots}
                         challengeId={challenge.id}
                         ownBot={ownBot}
                         onOwnBotSelection={onOwnBotSelection}
                         onReload={onReload}/>
            </div>
            }

            <div className="flex-[2] h-screen border">
                <CodeEditor bot={ownBot}
                            onSave={onSave}
                            onAction={onAction}
                            onReset={onReset}
                            tournaments={tournaments}
                            gameStarted={gameStarted}
                            onReload={onReload}
                            leftSide={isBotOnLeftSide}
                            jobs={jobs}
                            challengeId={challengeId!}
                            switchSide={switchSide}/>
            </div>
            <div className="flex-[2] h-screen border flex justify-start items-center p-10 flex-col">

                {challenge && <GameCanvas strategies={buildStrategies()} gameType={challenge.id}
                                          width={Number(challenge.config.smallWindowWidth)}
                                          start={gameStarted}
                                          height={Number(challenge.config.smallWindowHeight)}
                                          manualControl={{
                                              0: !isBotOnLeftSide && !opponentBot,
                                              1: isBotOnLeftSide && !opponentBot
                                          }}
                                          config={challenge.config}></GameCanvas>

                }
                <div className="mt-10">
                    To test your bot, you can play with the keyboard by using the up and down arrow keys for the
                    right
                    side or the w and s keys for the left side.
                    <br/>
                    You can also test your current bot against other ones.
                    <Select onChange={(e) => {
                        const bot = bots?.find(bot => bot.id === e.target.value);
                        setOpponentBot(bot);
                    }}>
                        <option value={""}>
                            No selection
                        </option>
                        {bots && bots.map(bot => <option value={bot.id}>
                            {bot.botName}
                        </option>)}

                    </Select>

                </div>
            </div>
        </div>

    </div>
}