import p5Types from "p5";

export class Shot {
    public length = 5;
    public hit = false;

    constructor(public p5: p5Types, public x: number, public y: number) {}

    draw() {
        if (!this.hit) {
            this.p5.stroke("#86bc25");
            this.p5.strokeWeight(this.p5.height / 400);
            this.p5.line(this.x, this.y, this.x, this.y - this.length);
        }
    }

    move() {
        this.y -= 12;
    }
}