import {Amplify, Auth} from "aws-amplify";

Amplify.configure({
    "Auth": {
        "region": "eu-central-1",
        "userPoolId": "eu-central-1_0rj8dxMDL",
        "userPoolWebClientId": "56q74j8pgk6ut2i2c0evdnr9fp",
        "mandatorySignIn": true,
        "redirectSignIn": "https://bridgevaders.bridgefield.de",
        "redirectSignOut": "https://bridgevaders.bridgefield.de"
    },

});

Auth.configure({
    oauth: {
        "domain": "https://bridgevaders.bridgefield.de",
        "scope": [
            "phone",
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://bridgevaders.bridgefield.de",
        "redirectSignOut": "https://bridgevaders.bridgefield.de",
        "responseType": "token"
    }
});

export enum ErrorType {
    UserNotConfirmedException = 'UserNotConfirmedException',
    NotAuthorizedException = "NotAuthorizedException"
}