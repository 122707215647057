import styles from "../styles/imprint.module.scss";

export const  ImprintPage = ()=> {
    return (
        <div className="w-[90%] md:w-[70%] xl:w-[40%] bg-white ml-auto mr-auto">
            <section className="p-10 md:p-20">
                <h1 className="text-[#86bc24] text-3xl md:text-4xl">IMPRESSUM</h1>
            </section>
            <section className="p-10 md:pt-2 md:p-20 ">
                <h2 className="text-black font-medium text-sm md:text-lg pt-10">ANGABEN GEMÄSS § 5 TM</h2>
                <h2 className="text-black font-medium text-sm md:text-lg pt-10">ALLGEMEINE HINWEISE</h2>
                <p className="text-black font-medium text-sm md:text-lg pt-10">
                    bridgefield GmbH
                    <br />
                    Jerichower Straße 28-30
                    <br />
                    39114 Magdeburg
                    <br /> <br />
                    Handelsregister: HRB 22812
                    <br />
                    Registergericht: Amtsgericht Stendal
                    <br />
                    Vertreten durch:
                    <br />
                    Prof. Dr. Frank Ortmeier
                </p>
            </section>
        </div>
    );
}
