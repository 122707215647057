import p5Types from "p5";
import {Paddle} from "./Paddle";

export class Ball {
    private k = 0;
    public ballStoped = false;

    constructor(
        private p5: p5Types,
        public position: p5Types.Vector,
        private radious: number,
        private velocity: p5Types.Vector
    ) {
    }

    public draw() {
        this.p5.ellipse(
            this.position.x,
            this.position.y,
            this.radious * 2,
            this.radious * 2
        );
    }

    public update(players: Paddle[]) {
        if (!this.ballStoped) {
            for (let i = 0; i < players.length; i++) {
                this.collide(players[i]);
            }

            if (this.position.x > this.p5.width) {
                for (let i = 0; i < players.length; i++) {
                    if (players[i].isLeft) {
                        players[i].score++;
                    }
                }

                this.ballStoped = true;
                setTimeout(() => {
                    this.ballStoped = false;
                }, 2000);

                this.drawMiddle();
                this.reset();
            } else if (this.position.x < 0) {
                for (let i = 0; i < players.length; i++) {
                    if (!players[i].isLeft) {
                        players[i].score++;
                    }
                }

                this.ballStoped = true;
                setTimeout(() => {
                    this.ballStoped = false;
                }, 2000);

                this.drawMiddle();
                this.reset();
            } else {
                this.bounce();
                this.position.x += this.velocity.x;
                this.position.y += this.velocity.y;
            }
        }
    }

    public bounce() {
        if (
            this.position.y > this.p5.height - this.p5.height / 10 - this.radious ||
            this.position.y < this.p5.height / 10 + this.radious
        ) {
            this.velocity.y *= -1;
        }
    }

    public reset() {
        var angle = this.p5.random(-this.p5.PI / 4, this.p5.PI / 4);
        this.velocity.x = 10 * this.p5.cos(angle);
        this.velocity.y = 10 * this.p5.sin(angle);

        this.velocity.x *= -1;
    }

    public collide(player: Paddle) {
        var rad = this.p5.radians(45);

        if (player.isLeft) {
            if (this.position.x < player.position.x + player.width + this.radious) {
                if (
                    this.position.y > player.position.y &&
                    this.position.y < player.position.y + player.height
                ) {
                    var diff = this.position.y - player.position.y;
                    var angle = this.p5.map(diff, 0, player.height, -rad, rad);
                    this.velocity.x = (25 + this.k) * this.p5.cos(angle);
                    this.velocity.y = (25 + this.k) * this.p5.sin(angle);
                    this.k = Math.min(this.k + 1, 32);
                }
            }
        } else {
            if (this.position.x > player.position.x - this.radious) {
                if (
                    this.position.y > player.position.y &&
                    this.position.y < player.position.y + player.height
                ) {
                    var diff = this.position.y - player.position.y;
                    var angle = this.p5.map(diff, 0, player.height, -rad, rad);
                    this.velocity.x = (25 + this.k) * this.p5.cos(angle) * -1;
                    this.velocity.y = (25 + this.k) * this.p5.sin(angle);
                    this.k = Math.min(this.k + 1, 32);
                }
            }
        }
    }

    public drawMiddle() {
        this.position.x = this.p5.width / 2;
        this.position.y = this.p5.height / 2;
    }
}
