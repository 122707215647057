import {Modal as FlowbiteModal} from "flowbite-react";
import React from "react";

interface IModalProps {
    title: string;
    visible: boolean;
    onClose: () => void;
    content: JSX.Element;
}

export const Modal = (props: IModalProps) => <FlowbiteModal show={props.visible}
                                                            onClose={props.onClose}
                                                            size="4xl">
    <FlowbiteModal.Header className="bg-white">
        <div className="text-black">{props.title}</div>
    </FlowbiteModal.Header>
    <FlowbiteModal.Body className="bg-white">
        <div className="space-y-6">
            {props.content}
        </div>
    </FlowbiteModal.Body>
</FlowbiteModal>