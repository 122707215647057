import {GameCanvas} from "../components/Challenge/Canvas";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {IChallenge} from "./Challenge";
import {getChallenge} from "../requests/public";
import {TooltipIcon} from "../components/Icon";
import {Auth} from "aws-amplify";
import {inviteFreind} from "../requests/private";
import {toast} from "react-toastify";
import {Tooltip} from "flowbite-react";

export const P2P = () => {
    const challengeId = useParams().id;
    const [start, setStart] = useState<boolean>(false);
    const [challenge, setChallenge] = useState<IChallenge>();
    const [connectedGamepads, setConnectedGamepads] = useState<number>(0);
    const [gamepadConnections, setGamepadConnections] = useState<number>(0);

    const onStart = () => {
        if (connectedGamepads > 0)
            setStart(!start)
    };

    useEffect(() => {
        loadChallenge();
    }, [challengeId]);


    useEffect(() => {
        setConnectedGamepads(0);
        setInterval(() => {
            let cGamepads = 0;
            for (const gamepad of navigator.getGamepads()) {
                if (!gamepad) continue;
                cGamepads++;
            }
            setConnectedGamepads(cGamepads);
        }, 1500);
    }, []);

    useEffect(() => {
        if (navigator) {
            let cGamepads = 0;
            for (const gamepad of navigator.getGamepads()) {
                if (!gamepad) continue;
                cGamepads++;
            }
            setConnectedGamepads(cGamepads);
        }
    }, [gamepadConnections]);

    const loadChallenge = () => {
        if (challengeId) {
            getChallenge(challengeId)
                .then(res => {
                    setChallenge(res.data)
                }).catch(e => console.log("Error: "))
        }
    }

    return (<div className="flex flex-col">
        <div className="flex bg-white h-16 min-w-screen border-b">
            <div className="flex justify-between items-center w-full">
                <div className="w-80 flex justify-center items-center gap-5">
                    <h1 className="text-xl font-extrabold leading-none tracking-tight text-black md:text-5xl lg:text-2xl uppercase">{challenge?.title}</h1>
                </div>
                <div className="mr-10 flex justify-center items-center gap-10">
                    <div
                        className="text-xl font-extrabold leading-none tracking-tight text-black md:text-5xl lg:text-2xl uppercase">
                        Number of connected gamepads: {connectedGamepads}</div>

                    <Tooltip content={start ? "Stop" : "Start"}>
                        <button type="button"
                                disabled={connectedGamepads === 0}
                                onClick={onStart}
                                className="disabled:bg-gray-600  text-white text-lg bg-[#002850] hover:bg-[#86bc24]/90 font-medium rounded-3xl p-4 text-center mr-2 mb-2 uppercase flex gap-1 justify-center items-center">
                            {start && <svg className="w-4 h-4 text-gray-800" aria-hidden="true"
                                           xmlns="http://www.w3.org/2000/svg" fill="none"
                                           viewBox="0 0 12 16">
                                <path fill="white"
                                      d="M3 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm7 0H9a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z"/>
                            </svg>}
                            {!start && <svg className="w-4 h-4 text-gray-800" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 16 18">
                                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M1 1.984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L2.506 1.139A1 1 0 0 0 1 1.984Z"/>
                            </svg>}
                        </button>
                    </Tooltip>
                </div>

            </div>
        </div>
        <div className="flex justify-center items-center w-full">
            {challenge && <GameCanvas strategies={[]} gameType={challenge.id}
                                      width={1900}
                                      start={start}
                                      height={1203}
                                      manualControl={{
                                          0: true,
                                          1: true
                                      }}
                                      config={{
                                          ballRadius: 20,
                                          ballSpeedX: 25,
                                          fontSize: 30,
                                          logoHeight: 10,
                                          logoWidth: 20,
                                          paddleHeight: 180,
                                          paddleVelocity: 15,
                                          paddleWidth: 20
                                      }}></GameCanvas>
            }
        </div>

    </div>)
}