import p5Types from "p5";


export class Alien {
  public alive: boolean;
  public explosionTimer: number;

  constructor(
    public p5: p5Types,
    public x: number,
    public y: number,
    public alienWidth: number,
    public alienHeight: number,
    public canvasWidth: number,
    public canvasHight: number,
    public imageA: any,
    public imageB: any,
    public points: number,
    public direction: number
  ) {
    this.alive = true;
    this.explosionTimer = 3;
  }

  draw() {
    if (this.alive) {
      this.p5.image(
        this.imageA,
        this.x,
        this.y,
        this.alienWidth,
        this.alienHeight
      );
    }
    if (!this.alive) {
      if (this.explosionTimer > 0) {
        this.die();
        this.explosionTimer -= 1;
      }
    }
  }

  moveHorizontal() {
    if (this.x <= 60 || this.x >= this.canvasWidth - 60) {
      this.direction = this.direction === 0 ? 1 : 0;
    } else if (this.p5.random() > 0.96) {
      this.direction = this.direction === 0 ? 1 : 0;
    }

    if (this.direction === 0) {
      this.x -= 5;
    }
    if (this.direction === 1) {
      this.x += 5;
    }
  }

  die() {
    this.p5.push();
    this.p5.translate(this.x, this.y);
    this.p5.noFill();
    this.p5.stroke(255);
    this.p5.strokeWeight(2);
    for (let i = 0; i < 10; i++) {
      this.p5.line(
        this.p5.floor(this.p5.random(2, 8)),
        0,
        this.p5.floor(this.p5.random(10, 15)),
        0
      );
      this.p5.rotate(this.p5.random(0, (4 * this.p5.PI) / 10));
    }
    this.p5.pop();
  }
}
