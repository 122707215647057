import React, {useEffect, useState} from "react";
import {Button} from "flowbite-react";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import {
    createNewJob,
    deleteJob,
    editJob,
    getJobsSchema,
    getAllJobs,
    resetJobs
} from "../../requests/admin";
import {IJob} from "../../types";
import {toast} from "react-toastify";
import {Modal} from "../Modal";
import {Section} from "./Section";
import {Table} from "../Table";
import {TooltipIcon} from "../Icon";

export const Jobs = () => {
    const [uiSchema, setUiSchema] = useState();
    const [schema, setSchema] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [newJobModal, setNewJobModal] = useState(false);
    const [deleteJobModal, setDeleteJobModal] = useState<string | undefined>();
    const [editJobModal, setEditJobModal] = useState<string | undefined>();
    const [jobs, setJobs] = useState<IJob[]>();

    useEffect(() => {
        loadJobs();
        loadSchema()
    }, []);

    const loadSchema = () => {
        setIsLoading(true)
        getJobsSchema().then(res => {
            setSchema(res.jobSchema);
            setUiSchema(res.jobUiSchema)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const loadJobs = () => {
        setIsLoading(true)
        getAllJobs().then((res: any) => setJobs(res)).finally(() => {
            setIsLoading(false)
        })
    }

    const toggleNewJobModal = () => {
        setNewJobModal(!newJobModal);
    }

    const toggleDeleteJobModal = (id?: string) => {
        setDeleteJobModal(id);
    }

    const toggleEditJobModal = (id?: string) => {
        setEditJobModal(id);
    }

    const onSubmit = (job: any) => {
        createNewJob(job.formData).then(() => {
            loadJobs()
        }).catch(() => {
        }).finally(() => {
            toggleNewJobModal()
        })
    };

    const onDelete = (jobId: string) => {
        deleteJob(jobId).then(() => {
            loadJobs()
        }).catch(() => {
        }).finally(() => {
            toggleDeleteJobModal()
        })
    };

    const onEdit = (job: any) => {
        editJob(editJobModal as string, job.formData).then(() => {
            loadJobs()
        }).catch(() => {
        }).finally(() => {
            toggleEditJobModal()
        })
    };


    const onReset = () => {
        setIsLoading(true)
        resetJobs().then(() => toast.info("Jobs successfully restored"))
            .catch(e => toast.error(e.message))
            .finally(() => setIsLoading(false))
    }


    return <Section isLoading={isLoading} title={"Jobs"} children={<div>
        <Modal title={"Create new job"} visible={newJobModal} onClose={toggleNewJobModal}
               content={<div className="space-y-6">
                   <Form
                       schema={schema!}
                       validator={validator}
                       onSubmit={onSubmit}
                   />
               </div>}
        />

        <Modal title={"Edit job"} visible={editJobModal !== undefined} onClose={() => toggleEditJobModal()}
               content={<div className="space-y-6">
                   <Form
                       schema={schema!}
                       formData={jobs?.find(job => job.id === editJobModal)}
                       validator={validator}
                       onSubmit={onEdit}
                   />
               </div>}
        />

        <Modal title={"Delete job"} visible={deleteJobModal !== undefined} onClose={() => toggleDeleteJobModal()}
               content={<div className="text-center">
                   <h3 className="mb-5 text-lg font-normal text-gray-500 ">
                       Are you sure you want to delete this job?
                   </h3>
                   <h2 className="mb-5 text-lg font-normal text-gray-500 ">{deleteJobModal}</h2>
                   <div className="flex justify-center gap-4">
                       <Button color="failure" onClick={() => onDelete(deleteJobModal as string)}>
                           Yes, I'm sure
                       </Button>
                       <Button color="gray" onClick={() => toggleDeleteJobModal()}>
                           No, cancel
                       </Button>
                   </div>
               </div>}
        />

        <div className="flex w-full justify-end p-4">
            <button type="button"
                    className="text-white text-lg bg-[#002850] hover:bg-[#86bc24]/90 font-medium rounded-lg px-5 py-2.5 text-center mr-2 mb-2 uppercase"
                    onClick={onReset}>
                Reset Jobs
            </button>

            <button type="button"
                    className="text-white text-lg bg-[#002850] hover:bg-[#86bc24]/90 font-medium rounded-lg px-5 py-2.5 text-center mr-2 mb-2 uppercase"
                    onClick={toggleNewJobModal}>
                Add new job
            </button>
        </div>

        {jobs && <Table columns={["ID", "TITLE", "DESCRIPTION", "ACTIONS"]}
                        rows={jobs.map(job => [job.id, job.title, job.description,
                            <div className="flex justify-center gap-3">
                                <TooltipIcon icon={"edit"} tooltipText={"Edit job"} onClick={() => {
                                    setEditJobModal(job.id)
                                }}/>
                                <TooltipIcon icon={"delete"} tooltipText={"Delete job"} onClick={() => {
                                    setDeleteJobModal(job.id)
                                }}/>
                            </div>
                        ])}/>}
    </div>}/>
}