import React, {useEffect, useState} from "react";
import {
    createNewTournament,
    deleteTournament,
    getAllTournaments,
    getCreateNewTournamentSchema
} from "../../requests/admin";
import {Button, Modal as TestModal} from "flowbite-react";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import {TooltipIcon} from "../Icon";
import {Section} from "./Section";
import {Table} from "../Table";
import {Modal} from "../Modal";

export const Tournaments = () => {
    const [uiSchema, setUiSchema] = useState();
    const [schema, setSchema] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [newTournamentModal, setNewTournamentModal] = useState(false);
    const [deleteTournamentModal, setDeleteTournamentModal] = useState<string | undefined>();
    const [tournaments, setTournaments] = useState<any[]>();
    const [selectedTournament, setSelectedTournament] = useState<any>();


    useEffect(() => {
        loadTournaments();
        loadSchema()
    }, []);

    const loadTournaments = () => {
        setIsLoading(true)
        getAllTournaments().then(res => setTournaments(res)).finally(() => {
            setIsLoading(false)
        })
    }

    const loadSchema = () => {
        setIsLoading(true)
        getCreateNewTournamentSchema().then(res => {
            setSchema(res.tournamentSchema);
            setUiSchema(res.tournamentUiSchema)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const toggleNewTournamentModal = () => {
        setNewTournamentModal(!newTournamentModal);
    }

    const toggleDeleteTournamentModal = (id?: string) => {
        setDeleteTournamentModal(id);
    }


    const onSubmit = (tournament: any) => {
        createNewTournament(tournament.formData).then(() => {
            loadTournaments()
        }).catch(() => {
        }).finally(() => {
            toggleNewTournamentModal()
        })
    };

    const onDelete = (jobId: string) => {
        deleteTournament(jobId).then(() => {
            loadTournaments()
        }).catch(() => {
        }).finally(() => {
            toggleDeleteTournamentModal()
        })
    };


    return <Section title={"Tournaments"} isLoading={isLoading} children={<div>

        {selectedTournament && <div>
            <div className="font-bold uppercase text-3xl flex items-center gap-5">
                <button className="flex items-center p-2 hover:bg-gray-50 rounded-3xl"
                        onClick={() => setSelectedTournament(undefined)}>
                    <svg className="w-12 h-12 text-gray-800 " aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M13 5H1m0 0 4 4M1 5l4-4"/>
                    </svg>
                </button>
                Back to Tournaments
            </div>
        </div>}

        {!selectedTournament && <div>
            <div className="flex w-full justify-end p-4">
                <button type="button"
                        className="text-white text-lg bg-[#002850] hover:bg-[#86bc24]/90 font-medium rounded-lg px-5 py-2.5 text-center mr-2 mb-2 uppercase"
                        onClick={toggleNewTournamentModal}>
                    Create new Tournament
                </button>
            </div>

            {schema && uiSchema && <Modal title={"Create new tournament"} visible={newTournamentModal}
                                          onClose={() => toggleNewTournamentModal()} content={
                <Form
                    schema={schema}
                    uiSchema={uiSchema}
                    validator={validator}
                    onSubmit={onSubmit}
                />
            }/>}


            <Modal title={"Delete tournament"} visible={deleteTournamentModal !== undefined}
                   onClose={() => toggleDeleteTournamentModal()} content={
                <div className="text-center">
                    <h3 className="mb-5 text-lg font-normal text-gray-500 ">
                        Are you sure you want to delete this tournament?
                    </h3>
                    <h2 className="mb-5 text-lg font-normal text-gray-500 ">{deleteTournamentModal}</h2>
                    <div className="flex justify-center gap-4">
                        <Button color="failure" onClick={() => onDelete(deleteTournamentModal as string)}>
                            Yes, I'm sure
                        </Button>
                        <Button color="gray" onClick={() => toggleDeleteTournamentModal()}>
                            No, cancel
                        </Button>
                    </div>
                </div>
            }/>

            {tournaments && <Table
                columns={["ID", "TITLE", "DESCRIPTION", "CHALLENGE", "TOURNAMENT TYPE", "END_DATE", "WITH_VIP_CODES",
                    "NUMBER_OF_CODES", "STATUS", "IS_ACTIVE", "CODES", "USED_CODES", "ACTIONS"]}
                rows={tournaments.map(tournament=> [tournament.id, tournament.title, tournament.description, tournament.challengeId,
                tournament.tournamentType, tournament.endDate, String(tournament.withVipCodes), tournament.numberOfCodes, tournament.status, tournament.isValid,
                    <TooltipIcon icon="info" tooltipText={tournament.codes.join("-------\n\n")}></TooltipIcon>,
                    <TooltipIcon icon="info" tooltipText={tournament.usedCodes.join("-------\n\n")}></TooltipIcon>,
                    <div className="flex justify-center gap-3">
                        <TooltipIcon icon={"delete"} tooltipText={"Delete tournament"} onClick={() => {
                            setDeleteTournamentModal(tournament.id)
                        }}/>
                        <TooltipIcon icon={"open"} tooltipText={"Open tournament"} onClick={() => {
                            setSelectedTournament(tournament.id)
                        }}/>
                    </div>
                ])}/>}
        </div>
        }
    </div>}/>


}