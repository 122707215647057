import React, {useEffect, useState} from "react";
import {PRIMARY_COLOR_BLACK, PRIMARY_COLOR_GREEN, PRIMARY_COLOR_WHITE} from "../../styles/colors";
import {Button, Modal, Tooltip} from "flowbite-react";
import {createNewBot, deleteBot} from "../../requests/private";
import {IBot} from "../../pages/Challenge";
import {toast} from "react-toastify";
import {Auth} from "aws-amplify";

interface ISidebarProps {
    challengeId: string;
    bots: IBot[],
    ownBot?: IBot;
    onOwnBotSelection: (bot: IBot) => void;
    onReload: () => void;
}

export const Sidebar = (props: ISidebarProps) => {
    const [openNewModal, setOpenNewModal] = useState(false);
    const [botId, setBotId] = useState<string>();
    const [newBotName, setNewBotName] = useState<string>();
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        Auth.currentUserInfo().then(u => {
            if (u) setIsAuth(true)
        })
    }, []);
    const isSelected = (id: string) => {
        if (props.ownBot)
            return id === props.ownBot.id;
    }

    const toggleNewModal = () => {
        setOpenNewModal(!openNewModal)
    }

    const closeDeletionModal = () => {
        setBotId(undefined)
    }

    const onSubmit = () => {
        if (newBotName) {
            createNewBot(props.challengeId, newBotName).then(res => {
                props.onReload();
                setNewBotName("")
                toggleNewModal();
                toast.success("New bot was successfully created")
            })
        }
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewBotName(e.target.value)
    }

    const onBotDeletion = (id: string) => {
        closeDeletionModal();
        deleteBot(props.challengeId, id).then(res => {
            toast.info("Bot was successfully deleted")
            props.onReload()
        });
    }


    return <div className="min-h-full text-center">

        <Modal show={openNewModal} onClose={toggleNewModal} size="xl">
            <Modal.Header className="bg-white"><div className="text-black"></div>Create new bot</Modal.Header>
            <Modal.Body className="bg-white text-black">
                <div className="space-y-6">
                    <div className="mb-6">
                        <label htmlFor="bot-name"
                               className="block mb-2 text-sm font-medium text-gray-900 ">Bot name</label>
                        <input onChange={onInputChange} type="text" id="bot-name"
                               className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500"/>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="bg-white text-black">
                <button
                    onClick={onSubmit}
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit
                </button>
            </Modal.Footer>
        </Modal>


        <Modal show={botId !== undefined} onClose={closeDeletionModal} size="6xl">
            <Modal.Header>Delete bot</Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h3 className="mb-5 text-lg font-normal text-gray-500 ">
                        Are you sure you want to delete this bot?
                    </h3>
                    <h2 className="mb-5 text-lg font-normal text-gray-500">{botId}</h2>
                    <div className="flex justify-center gap-4">
                        <Button color="failure" onClick={() => onBotDeletion(botId!)}>
                            Yes, I'm sure
                        </Button>
                        <Button color="gray" onClick={closeDeletionModal}>
                            No, cancel
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <div className="p-4 border">
            <p className="text-2xl text-black md:text-xl">Your stored bots</p>
        </div>
        <div>
            {props.bots.map(bot => <div
                onClick={() => props.onOwnBotSelection(bot)}
                className={`w-full h-16 flex justify-between pl-10 pr-10 items-center rounded md:text-xl bg-[${isSelected(bot.id) ? PRIMARY_COLOR_GREEN : PRIMARY_COLOR_WHITE}] 
                text-[${isSelected(bot.id) ? PRIMARY_COLOR_WHITE : PRIMARY_COLOR_BLACK}] cursor-pointer`}>
                <span>{bot.botName}</span>

                {isAuth && <button
                    className={`items-center p-2 hover:bg-gray-50 rounded-3xl ${!isSelected(bot.id) ? "hidden" : "flex"}`}
                    onClick={() => setBotId(bot.id)}>
                    <svg
                        className="flex-shrink-0 w-5 h-5 text-black transition duration-75 "
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                        viewBox="0 0 18 20">
                        <path
                            d="M17 4h-4V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H1a1 1 0 0 0 0 2h1v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1a1 1 0 1 0 0-2ZM7 2h4v2H7V2Zm1 14a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v8Zm4 0a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v8Z"/>
                    </svg>
                </button>}
            </div>)}
        </div>


        <div className="w-full flex justify-center items-center mt-10">
            <Tooltip content={isAuth ? "Create new bot" : "Only authenticated users can create new bots"}>
                <button type="button"
                        onClick={toggleNewModal}
                        disabled={!isAuth}
                        className="text-white text-lg bg-[#002850] hover:bg-[#86bc24]/90 font-medium rounded-3xl p-4 text-center mr-2 mb-2 uppercase flex gap-1 justify-center items-center">
                    <svg className="w-4 h-4 text-gray-800" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M9 1v16M1 9h16"/>
                    </svg>
                </button>
            </Tooltip>
        </div>

    </div>
}