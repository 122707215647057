import React from "react";
import {v4 as uuid} from "uuid";

interface ITableProps {
    columns: string[],
    rows: any[][]
}

export const Table = (props: ITableProps) => <table className="w-full text-sm text-left text-gray-500">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
    <tr>
        {props.columns.map(column => <th key={uuid()} scope="col" className="px-6 py-3">
            {column}
        </th>)}
    </tr>
    </thead>
    <tbody>
    {props.rows.map(row => <>
        <tr className="bg-white border-b ">
            {row.map(cell => <td key={uuid()} className="px-6 py-4">
                {cell}
            </td>)}
        </tr>
    </>)}
    </tbody>
</table>