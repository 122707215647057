import {Section} from "../components/Section";
import React, {useEffect, useState} from "react";
import {getAllJobs} from "../requests/public";
import {Card} from "../components/Card";
import {IJob} from "../types";

export const JobsPage = () => {
    const [jobs, setJobs] = useState<IJob[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        getAllJobs(["id", "description", "title"]).then((res) => {
            setJobs(res.data);
        }).finally(() => {
            setIsLoading(false)
        })
    }, []);

    return <div>
        {isLoading && <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
            <div
                className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-64 w-64"></div>
        </div>}
        {!isLoading && <Section title="Career Opportunities" subTitle="Exploring Your Future">
            {jobs && jobs.map((job: IJob) => <Card title={job.title}
                                                   description={job.description}
                                                   buttonTitle={"Apply now"}
                                                   path={job.id}
                                                   key={job.id}/>)}
        </Section>}

    </div>
}