import React, {useEffect, useState} from "react";
import Editor from "@monaco-editor/react";
import {IBot, ITournament} from "../../pages/Challenge";
import {toast} from "react-toastify";
import {Modal, ToggleSwitch, Tooltip} from "flowbite-react";
import {GameLogs} from "../../games/events";
import {getCodeApplicationsForm, registerBot, unregisterBot} from "../../requests/private";
import {Auth} from "aws-amplify";
import {IJob} from "../../requests/jobs";
import {TooltipIcon} from "../Icon";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import {submitApplication} from "../../requests/public";

interface ICodeEditorProps {
    bot?: IBot;
    onSave: (botID: string, code: string, name: string) => void;
    onAction: (bot: IBot) => void;
    onReload: () => void;
    onReset: (id: string, botName: string) => void;
    tournaments: ITournament[],
    gameStarted: boolean;
    leftSide: boolean;
    switchSide: (e: boolean) => void;
    jobs: IJob[],
    challengeId: string;
}

export const CodeEditor = (props: ICodeEditorProps) => {
    const [code, setCode] = useState<string>();
    const [selectedTournament, setSelectedTournament] = useState<string>();
    const [logs, setLogs] = useState<string[]>([]);
    const [openForm, setOpenForm] = useState(false);
    const [bonusCode, setBonusCode] = useState("");
    const [isAuth, setIsAuth] = useState(false);
    const [openApplicationForm, setOpenApplicationForm] = useState(false);
    const [schema, setSchema] = useState();
    const [uiSchema, setUiSchema] = useState();
    const [formData, setForm] = useState<any>()

    useEffect(() => {
        Auth.currentUserInfo().then(u => {
            if (u) {
                setForm({
                    firstName: u["attributes"].given_name,
                    lastName: u["attributes"].family_name,
                    email: u["attributes"].email
                });
                setIsAuth(true)
            }
        });
        loadForm();
    }, []);


    GameLogs.subscribe((e: string) => {
        const newLogs = [...logs, e];
        setLogs(newLogs);
    });

    useEffect(() => {
        if (props.gameStarted) {
            setLogs([]);
        }
    }, [props.gameStarted]);

    useEffect(() => {
        if (props.bot) {
            setCode(props.bot.code)
        }
    }, [props.bot]);


    const onCodeChange = (code?: string) => {
        setCode(code)
    }

    const onSave = () => {
        if (code && props.bot) {
            props.onSave(props.bot.id, code, props.bot.botName);
            toast.info("Code was successfully updated")
        }
    }

    const onAction = () => {
        if (props.bot && code)
            props.onAction({...props.bot, code})
    };

    const onReset = () => {
        if (props.bot)
            props.onReset(props.bot?.id, props.bot.botName);
        toast.info("Code was successfully rested")
    }

    const onTournamentSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTournament(e.target.value);
    }

    const toggleApplicationForm = () => {
        setOpenApplicationForm(!openApplicationForm)
    }

    const loadForm = () => {
        getCodeApplicationsForm().then(res => {
            setSchema(res.data.schema);
            setUiSchema(res.data.schema);
        }).finally(() => {
        })

    }

    const toggleForm = () => {
        const tour = props.tournaments.find(tournament => tournament.id === selectedTournament);
        if (tour && tour.withVipCodes) {
            setOpenForm(!openForm);
        } else if (tour) {
            onPaticipate()
        }
    }

    const onBonusCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBonusCode(e.target.value);
    }

    const onPaticipate = async () => {
        if (selectedTournament)
            registerBot(selectedTournament, {
                bot: {code, botName: props.bot?.botName},
                ...(await getUserInfo()),
                bonusCode: bonusCode
            })
                .then(res => {
                    toast.success(res.data);
                })
                .catch(e => {
                    toast.error(e.response.data);
                })
                .finally(() => {
                    setOpenForm(false);
                    props.onReload();
                })
    }

    const getUserInfo = async () => {
        try {
            return Auth.currentUserInfo().then(res => {
                return {
                    email: res.attributes["email"],
                    lastName: res.attributes["family_name"],
                    firstName: res.attributes["given_name"],
                }
            })
        } catch (e) {
            return {}
        }

    }

    const onSwitchSide = (e: boolean) => {
        props.switchSide(e)
    }

    const onUnregister = () => {
        if (selectedTournament)
            unregisterBot(selectedTournament)
                .then(res => {
                    toast.info(res.data);
                })
                .catch(e => {
                    toast.error(e.response.data);
                })
                .finally(() => {
                    props.onReload();
                })
    }

    const onSubmit = (formData: any) => {
        submitApplication({...formData.formData, bot: {code, challengeId: props.challengeId}}).then(() => {
            toast.success("Your application was successfully submitted.")
        }).finally(() => {
            toggleApplicationForm();
        })
    }


    return <div className="flex-1 min-h-full border ">

        <Modal show={openForm} onClose={toggleForm} size="xl">
            <Modal.Header className="bg-white text-black">
                <div className="bg-white text-black">Enter bonus code</div>
            </Modal.Header>
            <Modal.Body className="bg-white text-black">
                <div className="space-y-6 flex justify-center items-center flex-col">
                    <input type="text"
                           onChange={onBonusCodeChange}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-200 focus:border-green-50 block w-full pl-10 p-2.5"
                           required placeholder="Bonus Code"/>

                    <button type="button"
                            onClick={onPaticipate}
                            className="px-4 py-2 text-sm font-medium text-gray-900 rounded-lg bg-white border border-gray-200  hover:bg-gray-100 focus:z-10  disabled:bg-gray-400">
                        Participate
                    </button>
                </div>

            </Modal.Body>
        </Modal>

        <Modal show={openApplicationForm} onClose={() => setOpenApplicationForm(false)} size="6xl">
            <Modal.Header className="bg-white text-black">
                <div className="bg-white text-black">Apply to this job</div>
            </Modal.Header>
            <Modal.Body className="bg-white text-black">
                <div className="space-y-6">
                    {schema && uiSchema && <Form
                        schema={(schema as any)}
                        uiSchema={uiSchema}
                        validator={validator}
                        onSubmit={onSubmit}
                        formData={formData}
                    />}

                </div>
            </Modal.Body>
        </Modal>

        <div className="border flex flex-col">
            <div className="h-24 w-full flex justify-around items-center gap-5">

                <div className="inline-flex rounded-md shadow-sm">
                    <Tooltip content={props.gameStarted ? "Stop" : "Start"}>
                        <button type="button"
                                onClick={onAction}
                                className="text-white text-lg bg-[#002850] hover:bg-[#86bc24]/90 font-medium rounded-3xl p-4 text-center mr-2 mb-2 uppercase flex gap-1 justify-center items-center">
                            {props.gameStarted && <svg className="w-4 h-4 text-gray-800" aria-hidden="true"
                                                       xmlns="http://www.w3.org/2000/svg" fill="none"
                                                       viewBox="0 0 12 16">
                                <path fill="white"
                                      d="M3 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm7 0H9a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z"/>
                            </svg>}
                            {!props.gameStarted && <svg className="w-4 h-4 text-gray-800" aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 16 18">
                                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M1 1.984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L2.506 1.139A1 1 0 0 0 1 1.984Z"/>
                            </svg>}
                        </button>
                    </Tooltip>

                    <Tooltip content={isAuth ? "Save" : "Only authenticated users can save codes"}>
                        <button type="button"
                                disabled={!isAuth}
                                onClick={onSave}
                                className="text-white text-lg bg-[#002850] hover:bg-[#86bc24]/90 font-medium rounded-3xl p-4 text-center mr-2 mb-2 uppercase flex gap-1 justify-center items-center">
                            <svg className="w-4 h-4 text-gray-800 " aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 16 20">
                                <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                                <path
                                    d="M14.067 0H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.933-2ZM6.709 13.809a1 1 0 1 1-1.418 1.409l-2-2.013a1 1 0 0 1 0-1.412l2-2a1 1 0 0 1 1.414 1.414L5.412 12.5l1.297 1.309Zm6-.6-2 2.013a1 1 0 1 1-1.418-1.409l1.3-1.307-1.295-1.295a1 1 0 0 1 1.414-1.414l2 2a1 1 0 0 1-.001 1.408v.004Z"/>
                            </svg>
                        </button>
                    </Tooltip>

                    <Tooltip content={"Reset to default code"}>
                        <button type="button"
                                onClick={onReset}
                                className="text-white text-lg bg-[#002850] hover:bg-[#86bc24]/90 font-medium rounded-3xl p-4 text-center mr-2 mb-2 uppercase flex gap-1 justify-center items-center">
                            <svg className="w-4 h-4 text-gray-800 " aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"/>
                            </svg>
                        </button>
                    </Tooltip>


                </div>


                <ToggleSwitch
                    checked={props.leftSide}
                    label={props.leftSide ? "Play left" : "Play right"}
                    onChange={onSwitchSide}
                />
                <div className="inline-flex rounded-md shadow-sm justify-center items-center">

                    <div className="pr-5" onClick={toggleApplicationForm}>
                        <TooltipIcon icon="jobApplication" tooltipText={"Apply to Job with code"}></TooltipIcon>
                    </div>


                    <select
                        disabled={!isAuth}
                        onChange={onTournamentSelection}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg block w-72 p-2.5 focus:ring-gray-200">
                        <option selected value={""}>Select a tournament</option>
                        {props.tournaments && props.tournaments.map((tournament) => <option
                            value={tournament.id}>{tournament.title}</option>)}
                    </select>

                    {(!selectedTournament || !props.tournaments.find(tournament => tournament.id === selectedTournament)?.registeredBot) &&
                        <Tooltip className={isAuth ? "hidden" : ""}
                                 content={isAuth ? "" : "Only authenticated users can participate an tournaments"}>
                            <button type="button"
                                    onClick={toggleForm}
                                    disabled={!selectedTournament}
                                    className="px-1 py-3 text-sm font-medium text-gray-900 rounded-r-lg bg-white border border-gray-200  hover:bg-gray-100 focus:z-10  disabled:bg-gray-400">
                                Participate
                            </button>
                        </Tooltip>
                    }

                    {selectedTournament && props.tournaments.find(tournament => tournament.id === selectedTournament)?.registeredBot &&
                        <button type="button"
                                onClick={onUnregister}
                                disabled={!selectedTournament}
                                className="px-4 py-2 text-sm font-medium text-gray-900 rounded-r-lg bg-white border border-gray-200  hover:bg-gray-100 focus:z-10  disabled:bg-gray-400">
                            already registered/unregister
                        </button>}
                </div>
            </div>

            <Editor height={600}
                    defaultLanguage="typescript"
                    theme="vs-light"
                    value={code}
                    onChange={onCodeChange}
            />

        </div>
        <div className="overflow-y-auto h-96">
            <div>Console:</div>
            {logs.map((log, index) => <div className="pl-10">Logs---{index + 1}: <span className="pl-10">{log}</span>
            </div>)}
        </div>
    </div>
}