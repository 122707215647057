import React, {useEffect, useState} from "react";
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import {
    editChallenge,
    getAllChallenges,
    getChallengesSchema,
    resetChallenges
} from "../../requests/admin";
import {toast} from "react-toastify";
import {Table} from "../Table";
import {TooltipIcon} from "../Icon";
import {Modal} from "../Modal";
import {Section} from "./Section";

export const Challenges = () => {
    const [uiSchema, setUiSchema] = useState();
    const [schema, setSchema] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [editChallengeModal, setEditChallengeModal] = useState<string | undefined>();
    const [challenges, setChallenges] = useState<any[]>();

    useEffect(() => {
        loadChallenges();
        loadSchema()
    }, []);

    const loadChallenges = () => {
        setIsLoading(true)
        getAllChallenges()
            .then(res => setChallenges(res))
            .catch(e => toast.error(e.message))
            .finally(() => setIsLoading(false))
    }

    const loadSchema = () => {
        setIsLoading(true)
        getChallengesSchema()
            .then(res => {
                setSchema(res.challengeSchema);
                setUiSchema(res.challengeUiSchema)
            }).catch(e => toast.error(e.message))
            .finally(() => setIsLoading(false))
    }

    const toggleEditChallengeModal = (id?: string) => {
        setEditChallengeModal(id);
    }

    const onEdit = (challenge: any) => {
        setIsLoading(true);
        toggleEditChallengeModal()
        editChallenge(editChallengeModal as string, challenge.formData)
            .then(() => {
                toast.info(`Challenge ${challenge.formData.id} was successfully updated`)
                loadChallenges()
            })
            .catch((e) => toast.error(e.message))
            .finally(() => {
                setIsLoading(false)
            })
    };

    const onReset = () => {
        setIsLoading(true)
        resetChallenges().then(() => toast.info("Challenges successfully restored"))
            .catch(e => toast.error(e.message))
            .finally(() => setIsLoading(false))
    }


    return <Section title={"Challenges"} isLoading={isLoading} children={
        <div>
            <div className="flex w-full justify-end p-4">
                <button type="button"
                        className="text-white text-lg bg-[#002850] hover:bg-[#86bc24]/90 font-medium rounded-lg px-5 py-2.5 text-center mr-2 mb-2 uppercase"
                        onClick={onReset}>
                    Reset challenges
                </button>
            </div>


            {schema && uiSchema && <Modal title={"Edit challenge"} visible={editChallengeModal !== undefined}
                                          onClose={() => toggleEditChallengeModal()} content={<Form
                schema={schema}
                uiSchema={uiSchema}
                formData={challenges?.find(challenge => challenge.id === editChallengeModal)}
                validator={validator}
                onSubmit={onEdit}
            />}/>}


            {challenges && <Table columns={["ID", "TITLE", "DESCRIPTION", "ACTIONS"]} rows={
                challenges.map(challenge => [challenge.id, challenge.title, challenge.description,
                    <td className="px-6 py-4 flex">
                        <TooltipIcon icon={"edit"} tooltipText={"Edit challenge"}
                                     onClick={() => setEditChallengeModal(challenge.id)}/>
                    </td>])
            }/>}
        </div>
    }/>
}
