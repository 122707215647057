import { Ship } from "./Ship";
import { Shot } from "./Shot";
import { Alien } from "./Alien";
import { Laser } from "./Laser";
import { ACTIONS } from "./Actions";
import { GameController, GamepadEventType } from "../GameController";
import p5Types from "p5";
import { GameStatus } from "../events";

interface Observation {
  lasers: { x: number; y: number }[];
  aliens: { x: number; y: number }[];
  ship: { x: number; y: number; width: number };
  view: { maxHeigth: number; maxWidth: number };
  currentScore: number;
  numberOfAliens: number;
}

export class SpaceInvadersGameController extends GameController {
  private readonly ship: Ship;
  private readonly shots: Shot[];
  private readonly aliens: Alien[];
  private readonly lasers: Laser[];
  private score: number;
  private readonly chanceOfFiringLaser: number;
  private readonly laserSpeed: number;
  private readonly numberOfAliens: number;
  private readonly alien1: p5Types.Image;
  private readonly alien2: p5Types.Image;

  constructor(
      private readonly p5: p5Types,
      strategies: { botName: string; code: string }[],
      private canvasHeight: number,
      private canvasWidth: number,
      private config: any,
      private manualControlBots: { [key: number]: boolean },
  ) {
    super(strategies, {
      0: ACTIONS.NO_ACTION,
    });
    this.ship = new Ship(p5, this.canvasWidth, this.canvasHeight);
    this.shots = [];
    this.aliens = [];
    this.lasers = [];
    this.score = 0;
    this.chanceOfFiringLaser = 0.15;
    this.laserSpeed = 3;
    this.numberOfAliens = 100;
    this.alien1 = p5.loadImage("/alien1_a.png");
    this.alien2 = p5.loadImage("/alien1_b.png");
    this.initAliens();
  }

  public buildObservation(): Observation {
    const lasers: any[] = [];
    const aliens: any[] = [];
    const ship = this.ship.getShipInformation();
    for (const laser of this.lasers) {
      lasers.push({ x: laser.x, y: laser.y });
    }
    for (const alien of this.aliens) {
      aliens.push({ x: alien.x, y: alien.y });
    }
    return {
      lasers,
      aliens,
      ship,
      view: { maxHeigth: this.p5.height, maxWidth: this.p5.width },
      currentScore: this.score,
      numberOfAliens: this.numberOfAliens,
    };
  }

  public run(start: boolean) {
    this.initLayout();
    this.checkStatus();
    this.drawAllAliens();
    this.moveAllAliens();
    this.fireLaser();
    this.drawAllLasers();
    this.moveAllLasers();
    this.drawShip();
    this.hitShip();
    this.drawAllShots();
    this.moveAllShots();
    this.hitAlien();
    this.drawScore();
    if (start) {
      if (this.manualControlBots[0]) this.manualControl(0);
      else this.botControl(0);
      this.ship.takeAction(this.actions[0], this.shots);
      this.actions[0] = ACTIONS.NO_ACTION;
    }
  }

  public initLayout() {
    this.p5.background("#002850");
    this.p5.strokeWeight(5);
    this.p5.stroke("#85BC23");
    this.p5.fill("#85BC23");

    this.p5.line(
      0,
      this.canvasHeight / 10,
      this.canvasWidth,
      this.canvasHeight / 10
    );

    this.p5.imageMode("center");
    // this.p5.image(
    //   this.backgroundImage,
    //   this.canvasWidth / 2,
    //   this.canvasHeight / 20,
    //   this.canvasWidth / 4
    // );
  }

  private drawShip() {
    this.ship.draw();
  }

  private initAliens() {
    for (let i = 0; i < this.numberOfAliens; i++) {
      this.aliens[i] = new Alien(
        this.p5,
        this.p5.random(65, this.canvasWidth - 65),
        this.p5.random(this.canvasHeight / 6, this.canvasHeight / 2),
        25,
        25,
        this.canvasWidth,
        this.canvasHeight,
        this.alien1,
        this.alien2,
        10,
        this.p5.int(this.p5.random(0, 2))
      );
    }
  }

  private drawAllAliens() {
    for (let alien of this.aliens) {
      alien.draw();
    }
  }
  private moveAllAliens() {
    for (let alien of this.aliens) {
      alien.moveHorizontal();
    }
  }

  private fireLaser() {
    if (this.p5.random() < this.chanceOfFiringLaser) {
      let i = this.p5.floor(this.p5.random(this.aliens.length));
      if (this.aliens[i].alive) {
        let l = new Laser(
          this.p5,
          this.aliens[i].x,
          this.aliens[i].y + this.aliens[i].alienHeight / 2,
          this.laserSpeed
        );
        this.lasers.push(l);
      }
    }
  }

  private drawAllLasers() {
    for (let laser of this.lasers) {
      laser.draw();
    }
  }

  private moveAllLasers() {
    for (let i = 0; i < this.lasers.length; i++) {
      this.lasers[i].move();
      if (
        this.lasers[i].x <= 0 ||
        this.lasers[i].x >= this.p5.width ||
        this.lasers[i].y <= 0 ||
        this.lasers[i].y >= this.p5.height
      ) {
        this.lasers.splice(i, 1);
      }
    }
  }

  private drawAllShots() {
    for (let shot of this.shots) {
      shot.draw();
    }
  }

  private moveAllShots() {
    for (let i = 0; i < this.shots.length; i++) {
      this.shots[i].move();
      if (
        this.shots[i].x <= 0 ||
        this.shots[i].x >= this.p5.width ||
        this.shots[i].y <= 0 ||
        this.shots[i].y >= this.p5.height
      ) {
        this.shots.splice(i, 1);
      }
    }
  }

  private hitAlien() {
    for (let shot of this.shots) {
      for (let alien of this.aliens) {
        if (
          shot.x > alien.x - alien.alienWidth / 2 &&
          shot.x < alien.x + alien.alienWidth / 2 &&
          shot.y - shot.length > alien.y - alien.alienHeight / 2 &&
          shot.y - shot.length < alien.y + alien.alienHeight / 2 &&
          !shot.hit &&
          alien.alive
        ) {
          alien.alive = false;
          shot.hit = true;
          this.score += 10;
        }
      }
    }
  }
  hitShip() {
    for (let laser of this.lasers) {
      let leftEdgeOfLaser = laser.x - 2;
      let rightEdgeOfLaser = laser.x + 2;
      let frontOfLaser = laser.y + 8;
      let backOfLaser = laser.y;
      let leftEdgeOfShip = this.ship.x - this.ship.shipWidth / 2;
      let rightEdgeOfShip = this.ship.x + this.ship.shipWidth / 2;
      let frontOfShip = this.ship.y - this.ship.shipHeight / 2;
      let backOfShip = this.ship.y + this.ship.shipHeight / 2;
      if (
        rightEdgeOfLaser > leftEdgeOfShip &&
        leftEdgeOfLaser < rightEdgeOfShip &&
        frontOfLaser > frontOfShip &&
        backOfLaser < backOfShip &&
        !laser.used
      ) {
        laser.used = true;
        this.ship.die();
      }
    }
  }

  checkStatus() {
    if (!this.ship.isAlive()) {
      this.score -= 20;
      GameStatus.next(true);
    }
  }

  drawScore() {
    this.p5.textSize(22);
    this.p5.fill(255);
    this.p5.text(
      "SCORE: " + this.score,
      this.canvasWidth / 10,
      this.canvasHeight / 16
    );
  }

  public botControl(index: number): void {
    const obj = JSON.stringify(this.buildObservation());
    this.workers[index].postMessage(obj);
  }

  public manualControl(index: number): void {
    const action = this.update(index);
    if (action) {
      if (action.eventType === GamepadEventType.AXIS) {
        if (action.axisIndex == 0) {
          if (action.axis == -1) {
            this.actions[index] = ACTIONS.RIGHT;
          } else {
            this.actions[index] = ACTIONS.LEFT;
          }
        }
      } else {
        this.actions[index] = ACTIONS.FIRE;
      }
    }
  }
}
