import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
    return <footer>
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
            <div className="flex justify-center gap-10 b-m2">
                <Link to="/contact">
                    <h2 className="text-sm md:text-xl text-white uppercase hover:text-[#86bc24]">KONTAKT</h2>
                </Link>
                <Link to="/imprint">
                    <h2 className="text-sm md:text-xl text-white uppercase hover:text-[#86bc24]">IMPRESSUM</h2>
                </Link>
                <Link to="/privacy">
                    <h2 className="text-sm md:text-xl text-white uppercase hover:text-[#86bc24]">DATENSCHUTZ</h2>
                </Link>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
            <div className="sm:flex sm:items-center sm:justify-between">

                <span className="text-sm md:text-xl text-white">© 2023 <a
                    href="https://bridgefield.de/" className="hover:underline">Bridgefield GmbH</a>. All Rights Reserved.

                </span>
                <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
                    <Link to="https://twitter.com/bridgefield?lang=en" className="text-white hover:text-[#86bc24]">
                        <svg className="w-5 md:w-7 h-5 md:h-7" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 20 17">
                            <path fillRule="evenodd"
                                d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                                clipRule="evenodd" />
                        </svg>
                        <span className="sr-only">Twitter</span>
                    </Link>

                    <Link to="https://www.instagram.com/bridgefield_gmbh/"
                        className="text-white hover:text-[#86bc24]">
                        <svg className="w-5 md:w-7 h-5 md:h-7" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd"
                                d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z"
                                clipRule="evenodd" />
                        </svg>
                        <span className="sr-only">Instagram</span>
                    </Link>

                    <Link to="https://www.kununu.com/de/bridgefield1" className="text-white hover:text-[#86bc24]">
                        <svg className="w-5 md:w-7 h-5 md:h-7" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M19,10.75c0-0.37,0.007-0.95,0.007-0.961V8.561c0-0.89-0.671-1.561-1.561-1.561l-1.895,0.002	c-0.58-0.025-1.114,0.287-1.408,0.85C13.981,8.204,13.993,8.558,14,8.816l0.003,0.121L14,10.25c0,0.28-0.098,0.75-0.75,0.75	l-0.516-0.003c-0.394-0.002-0.807-0.006-1.042,0.005c-1.078-0.073-1.975,0.152-2.695,0.542L8.999,2.83c0-0.005,0-0.104,0-0.109	c0.002-0.153,0-0.308-0.043-0.559C8.787,1.5,8.147,1.001,7.467,1.001L5.552,1.003C5.144,0.977,4.733,1.138,4.436,1.447	C4.138,1.756,3.979,2.184,4.002,2.602L4,8.755c-0.003,3.9-0.006,8.243,0.006,12.885c0,0.112,0.02,0.225,0.058,0.332	c0.22,0.62,0.831,1.019,1.377,1.022l0.799,0.007c0.353,0.003,0.595,0.006,1.216,0.006c0.031,0.002,0.063,0.003,0.095,0.003	c0.356,0,0.706-0.135,0.979-0.384c0.318-0.29,0.493-0.696,0.479-1.079L9.004,19.02c-0.001-0.539,0.208-1.046,0.589-1.428	S10.481,17,11.02,17h0.967c1.105,0,2.01,0.899,2.015,2.007l0.014,2.411c-0.014,0.754,0.358,1.14,0.626,1.319	c0.349,0.255,0.729,0.259,0.964,0.262h0.92c0.256,0.002,0.497,0.002,0.955-0.003c0.4,0.032,0.797-0.133,1.091-0.427	c0.293-0.294,0.449-0.691,0.428-1.034l0-4.13c0-1.573-0.965-2.736-1.81-3.454C18.026,13.196,19,12.04,19,10.75z" />
                        </svg>
                        <span className="sr-only">kununu</span>
                    </Link>


                    <Link to="https://www.xing.com/pages/bridgefieldgmbh" className="text-white hover:text-[#86bc24]">
                        <svg className="w-5 md:w-7 h-5 md:h-7" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd"
                                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-14.085 15l2.744-4.825-1.846-3.162h3.292l1.83 3.152-2.744 4.835h-3.276zm8.79-1.445l3.514 6.445h-3.252l-3.55-6.445 5.38-9.555h3.289l-5.381 9.555z"
                                clipRule="evenodd" />
                        </svg>
                        <span className="sr-only">Xing</span>
                    </Link>

                    <Link to="https://de.linkedin.com/company/bridgefield-gmbh"
                        className="text-white hover:text-[#86bc24]">
                        <svg className="w-5 md:w-7 h-5 md:h-7" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd"
                                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                                clipRule="evenodd" />
                        </svg>
                        <span className="sr-only">GitHub account</span>
                    </Link>
                    <Link to="https://github.com/bridgefield" className="text-white hover:text-[#86bc24]">
                        <svg className="w-5 md:w-7 h-5 md:h-7" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd"
                                d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"
                                clipRule="evenodd" />
                        </svg>
                        <span className="sr-only">GitHub account</span>
                    </Link>

                </div>
            </div>
        </div>
    </footer>
}