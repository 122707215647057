import p5Types from "p5";
import {GameController} from "../GameController";
import {ACTION} from "./Actions";
import {Ball} from "./Ball";
import {Paddle} from "./Paddle";
import {Game, GameStatus} from "../events";
import {ACTIONS} from "../space-invaders/Actions";


interface IConfig {
    "logoHeight": number;
    "logoWidth": number;
    "paddleVelocity": number,
    "ballRadius": number,
    "ballSpeedX": number,
    "ACTION": string[],
    "paddleWidth": number,
    "paddleHeight": number,
    "fontSize": number,
}

export class PongGameController extends GameController {
    private readonly ball: Ball;
    private readonly leftPlayer: Paddle;
    private readonly rightPlayer: Paddle;
    private readonly paddleWidth: number;
    private readonly paddleHeight: number;
    private readonly paddleVelocity: number;
    private readonly ballSpeed: p5Types.Vector;
    private readonly ballPosition: p5Types.Vector;
    private readonly ballRadius: number;
    private leftPaddleScore = 0;
    private rightPaddleScore = 0;

    constructor(
        private p5: p5Types,
        private strategy: { botName: string; code: string }[],
        private canvasHeight: number,
        private canvasWidth: number,
        private config: IConfig,
        private manualControlBots: { [key: number]: boolean },
        // private logoImage: any
    ) {
        super(strategy, {
            0: ACTIONS.NO_ACTION,
            1: ACTION.NO_ACTION,
        });

        this.paddleWidth = this.config.paddleWidth;
        this.paddleHeight = this.config.paddleHeight;
        this.paddleVelocity = this.config.paddleVelocity;

        this.ballSpeed = this.p5.createVector(
            this.config.ballSpeedX,
            this.config.ballSpeedX
        );

        this.ballRadius = this.config.ballRadius;

        this.ballPosition = this.p5.createVector(
            this.canvasWidth / 2,
            this.canvasHeight / 2
        );

        this.ball = new Ball(
            this.p5,
            this.ballPosition,
            this.ballRadius,
            this.ballSpeed
        );

        this.leftPlayer = new Paddle(
            this.p5,
            this.canvasWidth,
            this.canvasHeight,
            this.canvasHeight / 10,
            true,
            this.paddleWidth,
            this.paddleHeight,
            this.paddleVelocity,
            this.leftPaddleScore
        );

        this.rightPlayer = new Paddle(
            this.p5,
            this.canvasWidth,
            this.canvasHeight,
            this.canvasHeight / 10,
            false,
            this.paddleWidth,
            this.paddleHeight,
            this.paddleVelocity,
            this.rightPaddleScore
        );
    }

    private getObservation() {
        return {
            leftPaddlePosition: {
                height: this.leftPlayer.height,
                width: this.leftPlayer.width,
                x: this.leftPlayer.position.x,
                y: this.leftPlayer.position.y,
            },
            rightPaddlePosition: {
                height: this.rightPlayer.height,
                width: this.rightPlayer.width,
                x: this.rightPlayer.position.x,
                y: this.rightPlayer.position.y,
            },
            ballPosition: {
                x: this.ball.position.x,
                y: this.ball.position.y,
            },
        };
    }

    public run(start: boolean) {
        this.initLayout();
        this.leftPlayer.draw();
        this.rightPlayer.draw();
        this.ball.draw();
        if (start) {
            this.ball.update([this.leftPlayer, this.rightPlayer]);
            this.leftPlayer.update(this.actions[0]);
            this.rightPlayer.update(this.actions[1]);
            this.resetActions();
            if (this.manualControlBots[0]) {
                this.keyPressed(0);
                this.manualControl(0);
            } else {
                this.botControl(0);
            }
            if (this.manualControlBots[1]) {
                this.keyPressed(1);
                this.manualControl(1);
            } else this.botControl(1);

            this.checkStatus();
        }
    }

    private resetActions() {
        this.actions[0] = ACTION.NO_ACTION;
        this.actions[1] = ACTION.NO_ACTION;
    }

    public checkStatus() {
        if (this.leftPlayer.score >= 5) {
            this.initLayout();
            this.showScore();
            this.p5.noLoop();
            Game.next({
                homeTeam: this.strategy[0].botName,
                awayTeam: this.strategy[1].botName,
                winner: this.strategy[0].botName,
                score: [this.leftPaddleScore, this.rightPaddleScore],
            });
        }
        if (this.rightPlayer.score >= 5) {
            this.initLayout();
            this.showScore();
            this.p5.noLoop();
            Game.next({
                homeTeam: this.strategy[0].botName,
                awayTeam: this.strategy[1].botName,
                winner: this.strategy[1].botName,
                score: [this.leftPaddleScore, this.rightPaddleScore],
            });
        }
    }

    public manualControl(paddleIndex: number) {
        const action = this.update(paddleIndex);
        if (action) {
            if (action.axisIndex == 1) {
                if (action.axis <= -0.8) {
                    this.actions[paddleIndex] = ACTION.UP;
                } else if (action.axis >= 0.8) {
                    this.actions[paddleIndex] = ACTION.DOWN;
                }
            }
        }
    }

    public botControl(index: number) {
        const obj = JSON.stringify(this.getObservation());
        this.workers[index] && this.workers[index].postMessage(obj);
    }

    public initLayout() {
        this.p5.background("#002850");
        this.p5.strokeWeight(5);
        this.p5.stroke("#85BC23");
        this.p5.fill("#85BC23");
        this.p5.line(
            0,
            this.canvasHeight - this.canvasHeight / 10,
            this.canvasWidth,
            this.canvasHeight - this.canvasHeight / 10
        );
        this.p5.line(
            0,
            this.canvasHeight / 10,
            this.canvasWidth,
            this.canvasHeight / 10
        );
        this.p5.line(
            this.canvasWidth / 2,
            this.canvasHeight / 10,
            this.canvasWidth / 2,
            this.canvasHeight - this.canvasHeight / 10
        );
        this.p5.circle(
            this.canvasWidth / 2,
            this.canvasHeight / 2,
            this.canvasHeight / 5
        );

        this.showScore();

        this.p5.imageMode("center");
        // this.p5.image(
        //     this.logoImage,
        //     this.canvasWidth / 2,
        //     this.canvasHeight / 20,
        //     20,
        //     20
        // );

        if (this.ball.ballStoped) {
            this.p5.textAlign("center");
            this.p5.text(
                "New Round starts in two seconds",
                this.p5.width / 2,
                this.p5.height / 2
            );
            this.p5.textAlign("left");
        }

    }

    public showScore() {
        this.p5.textSize(this.config.fontSize);
        this.p5.fill(255);
        this.p5.strokeWeight(0);
        this.p5.text(
            `${
                this.strategy[0] && this.strategy[0].botName
                    ? this.strategy[0].botName
                    : "Player 1"
            }  :   ` + this.leftPlayer.score,
            this.canvasWidth / 9,
            this.canvasHeight / 16
        );
        this.p5.strokeWeight(0);
        this.p5.text(
            `${
                this.strategy[1] && this.strategy[1].botName
                    ? this.strategy[1].botName
                    : "Player 2"
            }  :   ` + this.rightPlayer.score,
            this.canvasWidth - this.canvasWidth / 3,
            this.canvasHeight / 16
        );
    }

    public keyPressed(paddleIndex: number) {
        if (this.p5.keyIsPressed) {
            if (this.p5.keyCode == this.p5.UP_ARROW) {
                this.actions[1] = ACTION.UP;
            } else if (this.p5.keyCode == this.p5.DOWN_ARROW) {
                this.actions[1] = ACTION.DOWN;
            }

            if (this.p5.keyCode == 87) {
                this.actions[0] = ACTION.UP;
            } else if (this.p5.keyCode == 83) {
                this.actions[0] = ACTION.DOWN;
            }
        }
    }
}
