import axios from "axios";

const PUBLIC_BASE_URL = "https://3v82x7r9s1.execute-api.eu-central-1.amazonaws.com/dev/public"//"https://3v82x7r9s1.execute-api.eu-central-1.amazonaws.com/dev/public";


export const getAllChallenges = async (filter?: string[]) => {
    const queryfilter = filter && filter.map(f => "filter=" + f);
    let url = PUBLIC_BASE_URL + "/challenges";
    if (queryfilter) url += "?" + queryfilter.join("&")
    let res = await axios.get(url);
    return await res.data;
}

export const getChallenge = (challengeId: string) => {
    return axios.get(`${PUBLIC_BASE_URL}/challenges/${challengeId}`);
}

export const getAllJobs = (filter?: string[]) => {
    const queryfilter = filter && filter.map(f => "filter=" + f);
    let url = PUBLIC_BASE_URL + "/jobs";
    if (queryfilter) url += "?" + queryfilter.join("&")
    return axios.get(url);
}

export const getJob = (jobId: string) => {
    return axios.get(PUBLIC_BASE_URL + `/jobs/${jobId}`);
}

export const getApplicationForm = async () => {
    const res = await axios.get(PUBLIC_BASE_URL + `/applications/schema`);
    return await res.data;
}

export const getAllTournaments = async () => {
    const res = await axios.get(PUBLIC_BASE_URL + `/tournaments`);
    return await res.data;
}

export interface ISubmitApplication {
    id?: string;
    documents: { name: string, file: string }[];
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    telephone: number;
    bot?: {
        challengeId: string,
        code: string
    }
}

export const submitApplication = async (application: ISubmitApplication) => {
    return axios.post(PUBLIC_BASE_URL + `/applications`, application);
}