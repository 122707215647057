import styles from "../styles/imprint.module.scss";

export const ContactPage = () => {
    return (
        <div className="w-[90%] md:w-[70%] xl:w-[40%] bg-white ml-auto mr-auto">
            <section className="p-10 md:p-20">
                <h1 className="text-[#86bc24] text-3xl md:text-4xl">KONTAKT</h1>
                <p className="text-black font-medium text-sm md:text-lg pt-10">
                    Sie erreichen uns von Montag bis Freitag zwischen 9:00 und 18:00 unter
                    +49 391 792 930 0. Alternativ senden Sie uns gern eine E-Mail unter
                    info@bridgefield.de.
                    <br />
                    Wir freuen uns auf Ihre Antwort bzw. auf Ihre Nachricht!
                </p>
            </section>
            <section className="p-10 md:p-20 pt-10">
                <h2 className="text-[#86bc24] text-3xl md:text-4xl">KONTAKTFORMULAR</h2>
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <form action="#" className="space-y-8">
                        <div>
                            <label htmlFor="name" className="block mb-2 text-sm md:text-lg font-medium text-gray-900">Name</label>
                            <input type="text" id="name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" required />
                        </div>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm md:text-lg font-medium text-gray-900">Email</label>
                            <input type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" required />
                        </div>
                        <div>
                            <label htmlFor="number" className="block mb-2 text-sm md:text-lg font-medium text-gray-900">Telefonnummer</label>
                            <input type="tel" id="number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" required />
                        </div>
                        <div>
                            <label htmlFor="subject" className="block mb-2 text-sm md:text-lg font-medium text-gray-900">Betreff</label>
                            <input type="text" id="subject" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" required />
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block mb-2 text-sm md:text-lg font-medium text-gray-900">Nachricht</label>
                            <textarea id="message" rows={6} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"></textarea>
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <button type="submit"
                                    onClick={() => {}}
                                    className="text-sm md:text-2xl lg:text-3xl text-white hover:text-white  border border-white bg-[#86bc24] font-medium rounded-lg  px-5 py-2.5 text-center mr-2 mb-2">
                                Einreichen
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
}
