import p5Types from "p5";

export class Laser {
    public sign: number;
    public used: boolean;

    constructor(private p5: p5Types, public x: number, public y: number, private speed: number) {
        this.sign = 1;
        this.used = false;
    }

    draw() {
        if (!this.used) {
            this.p5.noFill();
            this.p5.stroke("red");
            this.p5.strokeWeight(this.p5.height / 400);
            this.p5.line(this.x, this.y, this.x, this.y - 5);
        }
    }

    move() {
        this.y += this.speed;
    }
}