import React from "react";
import {Tooltip} from "flowbite-react";

interface IPropsTooltipIcon {
    icon: IconKeys,
    tooltipText: string
    onClick?: () => void;
}

type IconKeys = keyof typeof icons;


const icons = {
    info: <svg className="hover:cursor-pointer w-6 h-6 text-gray-800 inline" aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>,
    edit: <svg
        className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900"
        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
        viewBox="0 0 20 18">
        <path
            d="M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z"/>
        <path
            d="M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z"/>
    </svg>,
    delete: <svg
        className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900"
        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
        viewBox="0 0 18 20">
        <path
            d="M17 4h-4V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H1a1 1 0 0 0 0 2h1v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1a1 1 0 1 0 0-2ZM7 2h4v2H7V2Zm1 14a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v8Zm4 0a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v8Z"/>
    </svg>,
    open: <svg className="w-5 h-5 text-gray-500 " aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
        <path fill="currentFill"
              d="M17 0h-5.768a1 1 0 1 0 0 2h3.354L8.4 8.182A1.003 1.003 0 1 0 9.818 9.6L16 3.414v3.354a1 1 0 0 0 2 0V1a1 1 0 0 0-1-1Z"/>
        <path fill="currentFill"
              d="m14.258 7.985-3.025 3.025A3 3 0 1 1 6.99 6.768l3.026-3.026A3.01 3.01 0 0 1 8.411 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V9.589a3.011 3.011 0 0 1-1.742-1.604Z"/>
    </svg>,
    jobApplication: <svg fill="currentColor" className="hover:cursor-pointer w-6 h-6 text-gray-800 inline" version="1.1"
                         id="Capa_1"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 489.6 489.6" stroke="#4f4a4a">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <g>
                <g>
                    <polygon
                        points="311.6,437.9 182.2,472.5 191.6,437.9 44.7,437.9 44.7,51.7 337.7,51.7 337.7,219.9 382.4,175.2 382.4,3.1 0,3.1 0,486.5 382.4,486.5 382.4,369.2 "></polygon>
                    <polygon points="235.1,364.9 214.9,439.5 289.9,419.7 "></polygon>
                    <polygon
                        points="425.5,164.7 246.7,343.5 247.1,343.9 310.9,407.6 311.3,407.6 489.6,228.9 "></polygon>
                    <path
                        d="M292.6,105.3H91.7c-6.2,0-11.7,5.1-11.7,11.7c0,6.2,5.1,11.7,11.7,11.7h200.9c6.2,0,11.7-5.1,11.7-11.7 C304.3,110.7,298.8,105.3,292.6,105.3z"></path>
                    <path
                        d="M292.6,176.4H91.7c-6.2,0-11.7,5.1-11.7,11.7c0,6.2,5.1,11.7,11.7,11.7h200.9c6.2,0,11.7-5.1,11.7-11.7 C304.3,181.8,298.8,176.4,292.6,176.4z"></path>
                    <path
                        d="M304.3,259.2c0-6.2-5.1-11.7-11.7-11.7H91.7c-6.2,0-11.7,5.1-11.7,11.7c0,6.2,5.1,11.7,11.7,11.7h200.9 C298.8,270.8,304.3,265.4,304.3,259.2z"></path>
                </g>
            </g>
        </g>
    </svg>
}


export const TooltipIcon = (props: IPropsTooltipIcon) => {
    return <Tooltip content={props.tooltipText} placement="bottom">
        <button onClick={props.onClick}>{icons[props.icon]}</button>
    </Tooltip>
}

