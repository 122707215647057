import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Navbar} from "./components/Navbar";
import {JobsPage} from "./pages/Jobs";
import {ChallengesPage} from "./pages/Challenges";
import {AdminPage} from "./pages/Admin";
import {HomePage} from "./pages/Home";
import {JobPage} from "./pages/Job";
import {PrivacyPage} from "./pages/Privacy";
import {ContactPage} from "./pages/Contact";
import {ImprintPage} from "./pages/Imprint";
import {AuthPage} from "./pages/AuthPage";
import {SignOutPage} from "./components/Auth/SignOut";
import {ChallengePage} from "./pages/Challenge";
import {toast, ToastContainer} from 'react-toastify';
import {Auth, Hub} from 'aws-amplify'
import {P2P} from "./pages/p2p";
import "./config/config";
import "./App.css"
import 'react-toastify/dist/ReactToastify.css';


export default function App() {
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>();

    Hub.listen("auth", (e) => {
        setLoading(!loading)
    })

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(user => {
            setUserName(user.attributes["given_name"][0] + user.attributes["family_name"][0])
            setIsAuth(true);
            const group: string[] = user.signInUserSession.accessToken.payload["cognito:groups"];
            if (group && group.includes("Admin")) {
                setIsAdmin(true)
            }
        }).catch(() => {
            setIsAuth(false);
            setIsAdmin(false);
        });
    }, [loading]);


    return (
        <BrowserRouter>
            <ToastContainer/>
            <div className="layout">
                <Navbar isAuthenticated={isAuth} isAdmin={isAdmin} userName={userName}/>
                <Routes>
                    <Route path="/" element={<HomePage/>}></Route>
                    <Route path="/jobs" element={<JobsPage/>}></Route>
                    <Route path="/jobs/:id" element={<JobPage/>}></Route>
                    <Route path="/challenges" element={<ChallengesPage/>}></Route>
                    <Route path="/challenges/p2p/:id" element={<P2P/>}></Route>
                    <Route path="/challenges/:id" element={<ChallengePage/>}></Route>
                    <Route path="/auth/login" element={<AuthPage/>}/>
                    <Route path="/auth/signup" element={<AuthPage/>}/>
                    <Route path="/auth/confirm" element={<AuthPage/>}/>
                    <Route path="/auth/signout" element={<SignOutPage/>}/>
                    <Route path="/admin/" element={<AdminPage/>}></Route>
                    <Route path="/admin/applications/:id" element={<AdminPage/>}></Route>
                    <Route path="/admin/tournaments/:id" element={<AdminPage/>}></Route>
                    <Route path="/admin/:suffix" element={<AdminPage/>}></Route>
                    <Route path="/privacy" element={<PrivacyPage/>}></Route>
                    <Route path="/contact" element={<ContactPage/>}></Route>
                    <Route path="/imprint" element={<ImprintPage/>}></Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}