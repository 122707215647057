import { GameLogs } from "./events";

export enum GamepadEventType {
  BUTTON,
  AXIS,
}

export abstract class GameController {
  public abstract run(start: boolean): void;
  public abstract botControl(index: number): void;
  public abstract manualControl(index: number): void;
  public abstract initLayout(): void;
  public abstract checkStatus(): void;

  public workers: Worker[];
  public actions: { [key: number]: any };

  protected constructor(
    private strategies: { botName: string; code: string }[],
    actions: any
  ) {
    this.actions = actions;
    this.workers = this.strategies.map((strategy) => {
      const blob = new Blob([strategy.code]);
      return new Worker(window.URL.createObjectURL(blob));
    });

    this.workers.forEach((worker, index) => {
      worker.onmessage = (msg:any) => {
        if (msg.data && msg.data.type === 'log') {
          GameLogs.next(msg.data.message)
        }else{
          this.actions[index] = msg.data;
        }
      };
    });
  }

  public update(indexs: number) {
    for (const gamepad of navigator.getGamepads()) {
      if (!gamepad || gamepad.index !== indexs) continue;
      for (const [axisIndex, axis] of (gamepad as any).axes.entries()) {
        if (axis >= 0.3 || axis <= -0.3) {
          return {
            eventType: GamepadEventType.AXIS,
            gamepadIndex: gamepad.index,
            axisIndex,
            axis,
          };
        }
      }
    }
  }
}
