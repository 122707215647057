import React, {FormEvent, useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {ErrorType} from "../../config/config";
import {Auth} from "aws-amplify";

export const ConfirmationForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [code, setCode] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(res => {
            if (res.attributes["email_verified"]) {
                navigate("/")
            } else {
                sendCode()
            }
        }).catch(()=>{
            sendCode()
        })


    }, [location.search]);

    const sendCode = ()=>{
        const email = searchParams.get("email");
        const message = searchParams.get("message");
        if (email && message) {
            setEmail(email);
            setMessage(message);
            if (message == ErrorType.UserNotConfirmedException) {
                Auth.resendSignUp(email).then(res => {
                }).catch(e => console.log(e))
            }
        }
    }
    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (code) Auth.confirmSignUp(email!, code).then(res => {
            navigate("/auth/login");
        }).catch(e => {
            setError(e.message)
        })
    };

    const resendCode = () => {
        if (email) {
            Auth.resendSignUp(email).then(res => {
                console.log(res)
            }).catch(e => {
                console.log(e)
            })
        }
    }

    return <section>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto relative h-[80vh] lg:py-0">
            <div
                className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                        E-Mail-Bestätigung
                    </h1>
                    <h3>Ein Bestätigungscode wurde an die folgende E-Mail-Adresse gesendet. <span
                        className="font-bold block">{email}</span></h3>
                    <form className="space-y-4 md:space-y-6" action="#" onSubmit={onSubmit}>
                        <div>
                            <label htmlFor="code"
                                   className="block mb-2 text-sm font-medium text-gray-900">Verifizierungscode</label>
                            <input type="text" name="code" id="code"
                                   className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#86bc24] focus:border-[#86bc24] block w-full p-2.5"
                                   onChange={(e) => setCode(e.target.value)}
                                   required/>
                        </div>
                        <p className="mt-2 text-sm text-red-600">{error}</p>

                        <button type="submit"
                                disabled={code == undefined && code == ""}
                                className="disabled:bg-gray-400 w-full text-white bg-[#86bc24] hover:bg-primary-700 focus:ring-[#86bc24] focus:border-[#86bc24] font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            Bestätigen
                        </button>
                        <div className="text-sm font-light text-gray-500">
                            Code nicht erhalten? <div onClick={resendCode}
                                                      className="inline cursor-pointer font-medium text-primary-600 hover:underline"> Code
                            erneut senden</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
}