import {useNavigate} from "react-router-dom";

interface ICardProps {
    title: string;
    description: string;
    path: string;
    buttonTitle: string;
    secondButtonTitle?: string;
    secondPath?: string;
}

export const Card = (props: ICardProps) => {
    const navigate = useNavigate();
    return (<div
        className="relative h-[30rem] flex w-96 flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
        <div className="p-6">
            <h5 className="mb-2 block font-sans text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                {props.title}
            </h5>
            <p className="block h-[20rem] overflow-auto font-sans text-base font-light leading-relaxed text-inherit antialiased">
                {props.description}
            </p>
        </div>
        <div className="absolute bottom-6 left-6">
            <button
                className="select-none rounded-lg bg-[#86bc24] py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-[#86bc24]/20 transition-all hover:shadow-lg hover:shadow-[#86bc24]/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                data-ripple-light="true"
                onClick={() => navigate(props.path)}
            >
                {props.buttonTitle}
            </button>

            {props.secondButtonTitle && props.secondPath && <button
                className="ml-10 select-none rounded-lg bg-[#86bc24] py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-[#86bc24]/20 transition-all hover:shadow-lg hover:shadow-[#86bc24]/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                data-ripple-light="true"
                onClick={() => navigate(props.secondPath!)}
            >
                {props.secondButtonTitle}
            </button>}
        </div>
    </div>)
}