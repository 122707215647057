import React, {useEffect, useState} from "react";
import {getAllUsers, IUser} from "../../requests/admin";
import {toast} from "react-toastify";
import {Table} from "../Table";
import {Section} from "./Section";

export const Users = () => {
    const [users, setUsers] = useState<IUser[]>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = () => {
        setIsLoading(true)
        getAllUsers().then(res => {
            setUsers(res);
        }).catch((e) => {
            toast.error(e.message);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getAttr = (attrName: string, user: any) => {
        const attributes = user.Attributes;
        const res = attributes.find((att: { Name: string, Value: string }) => att.Name === attrName);
        if (res) {
            return res.Value;
        }
    }

    return <div className="p-14">
        <Section isLoading={isLoading} title={"Users"} children={users &&
            <Table columns={["ID", "Created at", "First name", "Family name", "Email", "Enabled", "Email Verified"]}
                   rows={users.map(user =>
                       [getAttr("sub", user),
                           user.UserCreateDate,
                           getAttr("given_name", user),
                           getAttr("family_name", user),
                           getAttr("email", user),
                           user.Enabled.toString(),
                           user.UserStatus])}/>}/>

    </div>
}