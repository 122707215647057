import {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import {Footer} from "../components/Footer";

export const HomePage = () => {
    const navigate = useNavigate();

    return <Fragment>
        <div className="relative h-[80vh]">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <h1 className="text-5xl md:text-7xl text-white text-center">BRIDGEFIELD</h1>
                <h1 className="text-4xl md:text-6xl text-white text-center">BEWERBUNGSPORTAL</h1>
                <hr
                    className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-[#86bc24] to-transparent"/>
                <div className="flex flex-col md:flex-row justify-center align-middle">
                    <button type="button"
                            onClick={() => navigate("/applications")}
                            className="text-1xl md:text-2xl lg:text-3xl text-white hover:text-white border border-white hover:bg-[#86bc24] font-medium rounded-lg  px-5 py-2.5 text-center mr-2 mb-2 ">
                        BEWIRB DICH MIT LEBENSLAUF
                    </button>

                    <button type="button"
                            onClick={() => navigate("/challenges")}
                            className="text-xl md:text-2xl lg:text-3xl text-white hover:text-white  border border-white bg-[#86bc24] font-medium rounded-lg  px-5 py-2.5 text-center mr-2 mb-2">
                        BEWIRB DICH MIT CODE
                    </button>
                </div>
            </div>
        </div>
        <Footer/>
    </Fragment>
}